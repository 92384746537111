import * as React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { cn } from '../../lib/utils'

const PhoneNumberInput = React.forwardRef(({ className, ...props }, ref) => {

  return (
    <PhoneInput
      className={cn(
        "flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-transparent placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      ref={ref}
      defaultCountry='FR'
      {...props}
    />
  )
})

PhoneNumberInput.displayName = "PhoneNumberInput"

export { PhoneNumberInput }
