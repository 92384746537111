import React, { Fragment } from "react";

// Components
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "./../../components/ui/form";
import interceptor from "../../../src/hooks/intercepter";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PhoneNumberInput } from "./../../components/ui/phoneInput";

const FormSchema = z.object({
  first_name: z.string().min(2, "First name is required"),
  last_name: z.string().min(2, "Last name is required"),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, "Location name is required"),
  location_address: z.string().min(1, "Location address  is required"),
  // password: z.string().min(1, 'Password is required').min(8, 'Password must have 8 character'),
  // confirmPassword: z.string().min(1, 'Password confirmation is required'),
});
// .refine((data) => data.password === data.confirmPassword, {
//   path: ['confirmPassword'],
//   message: 'Password do not match'
// })

const Signup = ({ handleDialogClose }) => {
  const naviagte = useNavigate();

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      location_name: "",
      location_address: "",
    },
  });

  const onSubmit = (value) => {
    console.log("Submited", value);
    interceptor
      .axiosPost("users/broadcaster/signup", value, null)
      .then((res) => {
        if (res.status === 200) {
          // naviagte("/broadcaster/dashboard")
          toast.success("Registered Successfully");
          handleDialogClose();
          form.reset(); // Reset the form fields
        } else {
          console.log("error getting user : ", res);
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <Fragment>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-6"
        >
          <div className="flex flex-col gap-5 mt-5">
            <div>
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre Prénom"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre Nom de famille"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre Email"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
              {/* <Input
                type="text"
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder="Email"
              /> */}
            </div>
            <div>
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <PhoneNumberInput
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre numéro de téléphone "
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
              {/* <Input
                type="text"
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder="Phone"
              /> */}
            </div>
            <div>
              <FormField
                control={form.control}
                name="location_name"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre nom de lieu"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
              {/* <Input
                type="text"
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder="Location Name"
              /> */}
            </div>
            <div>
              <FormField
                control={form.control}
                name="location_address"
                render={({ field }) => (
                  <FormItem className="space-y-0">
                    <FormControl>
                      <Input
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Votre adresse de localisation"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                  </FormItem>
                )}
              />
              {/* <Input
                type="text"
                className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                placeholder="Location Address"
              /> */}
            </div>
            <div>
              <Button size="sm" className="w-full md:px-12 px-9">
                s'inscrire
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Fragment>
  );
};

export default Signup;
