import { LoaderIcon } from "../../assets/icons/Icons";
import React from "react";

const Loader = (props) => {
  return (
    <div>
      <div className="bg-black bg-opacity-75 fixed left-0 top-0 h-screen w-full transition duration-400 z-20">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <LoaderIcon className="w-20 h-20 text-gray-200 animate-spin fill-blue" />
          <span className="text-lg font-medium text-white">
            {props.content ? props.content : "Validating"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
