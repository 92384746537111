import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import PaymentForm from "../PaymentForm"; // adjust path as needed
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import Layout from "../../../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import interceptor from "../../../../hooks/intercepter";
import useToken from "../../../../hooks/useToken";
import toast from "react-hot-toast";
import { GalleryIcon } from "../../../../assets/icons/Icons";
import Loader from "../../../../components/ui/Loader";



const StepOne = (prop) => {
  const { onSelectedFile, maxDuration, item } = prop
  const navigate = useNavigate();
  const token = useToken();
  const [id, setId] = useState();
  const [advertiserId, setAdvertiserId] = useState();
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [terms, setTerms] = useState(false);
  const [title, setTitle] = useState("");
  const [contentId, setContentId] = useState("");

  const [clientIntent, setClientIntent] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    package: "",
  });
  const [clientSecret, setClientSecret] = useState(null);
  const [duration, setDuration] = useState();
  // const userId = window.location.pathname.split("/")[3];


  const options = {
    clientSecret: clientSecret,
  };



  // useEffect(() => {
  //   if (id) setError(false);
  //   if (file) setError(false);
  //   if (formData.email) setError(false);
  //   if (terms) setError(false);
  // }, [id, file, formData.email, terms]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      package: id,
    }));
  };



  const generateVideoThumbnail = async (videoFile, durations) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = 1;
    onSelectedFile({
      file: videoFile,
      type: videoFile.type,
      name: videoFile.name,
      title: videoFile.name,
      videoThumbnail: null,
      imagePreview: null,
      duration: durations
    })
    video.onloadeddata = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      await setVideoThumbnail(thumbnailUrl);
      setImagePreview(null);
      onSelectedFile({
        file: videoFile,
        type: videoFile.type,
        name: videoFile.name,
        title: videoFile.name,
        videoThumbnail: thumbnailUrl,
        imagePreview: null,
        duration: durations
      })
    };
  };

  const handleFileChange = (e) => {
    debugger
    // if (!duration) {
    //   setError(true);
    //   setErrorMessage(`Please select package first.`);
    //   return;
    // }
    const selectedFile = e.target.files[0];

    if (selectedFile.type.startsWith("video/")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = async () => {
        setDuration(video.duration);
        console.log("duration", video.duration)
        if (video.duration > duration) {
          setError(true);
          setErrorMessage(
            `Please select a video that is no longer than ${duration} seconds.`
          );
          setFile(null);
          setFileType("");
          setFileName("");
          setVideoThumbnail(null);
          setImagePreview(null);
          e.target.value = null;
        } else {
          setFile(selectedFile);
          setFileType(selectedFile.type);
          setFileName(selectedFile.name);
          setTitle(selectedFile.name);

          await generateVideoThumbnail(selectedFile, video.duration);


        }
      };
    } else if (selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileName(selectedFile.name);
      setTitle(selectedFile.name);
      setImagePreview(URL.createObjectURL(selectedFile));
      setVideoThumbnail(null);
      onSelectedFile({
        file: selectedFile,
        type: selectedFile.type,
        name: selectedFile.name,
        title: selectedFile.name,
        videoThumbnail: null,
        imagePreview: URL.createObjectURL(selectedFile),
        duration: duration
      })
    }


  };

  useEffect(() => {
    debugger
    if (item?.videoThumbnail) setVideoThumbnail(item?.videoThumbnail);
    if (item?.imagePreview) setImagePreview(item?.imagePreview);
    if (item?.name) setFileName(item?.name);
    if (item?.file) setFile(item?.file);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section>
            <div className="md:container mx-auto px-5">
              <div className="lg:w-6/12 w-full mx-auto">
                <div className="flex flex-col items-center gap-2">
                  <h4 className="md:text-3xl text-2xl font-bold text-dark text-center">
                  Prends Le <span className="text-blue">Contrôle</span> De Cet Écran
                  </h4>
                  <p className="text-sm mt-2 text-center">
                  Tu souhaites partager un
                    <Link href="#" className="text-blue ml-1">
                      selfie
                    </Link>
                    ?
                    <Link href="#" className="text-blue ml-1">
                    Tes réseaux sociaux
                    </Link>
                    ? Afficher un
                    <Link href="#" className="text-blue mx-1">
                      message
                    </Link>
                    devant tout le monde?
                  </p>
                  <p className="text-sm text-center">
                  Sélectionne ta photo ou ta vidéo que tu souhaites publier à l'écran
                    !(Maximum de {maxDuration}secondes pour une vidéo)
                  </p>
                </div>
                <div className="bg-background rounded-xl md:p-12 p-6 flex flex-col gap-5 mt-5">
                  <label
                    htmlFor="picture"
                    className="flex flex-col items-center gap-8 px-8 py-12 border border-dashed border-blue bg-background rounded-xl sm:px-12 md:py-16"
                  >
                    {videoThumbnail ? (
                      <img
                        src={videoThumbnail}
                        alt="Video Thumbnail"
                        className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                      />
                    ) : imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Img Preview "
                        className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                      />
                    ) : (
                      <GalleryIcon
                        width={60}
                        height={60}
                        className="w-10 h-10 sm:w-14 sm:h-14"
                        stroke="#242331"
                      />
                    )}
                    <Input
                      id="picture"
                      className="hidden"
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*,video/*"
                    />
                    <h6 className="text-lg text-center">
                      {file ? (
                        <span>{fileName}</span>
                      ) : (
                        <>
                          Faites glisser et déposez des fichiers ici ou {" "}
                          <span className="font-semibold text-blue">
                            parcourez
                          </span>
                        </>
                      )}
                    </h6>
                  </label>
                  {uploadProgress > 0 && (
                    <div className="mt-4">
                      <progress
                        value={uploadProgress}
                        max="100"
                        className="w-full"
                      ></progress>
                      <span>{uploadProgress}%</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default StepOne;
