import React from "react";
import Switch from "react-switch";

const StatusToggle = ({ isActive, onToggle }) => {
  return (
    <Switch
      checked={isActive}
      onChange={onToggle}
      onColor="#68D391"
      offColor="#E53E3E"
      checkedIcon={false}
      uncheckedIcon={false}
      height={24}
      width={48}
    />
  );
};

export default StatusToggle;
