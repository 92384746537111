import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const DashboardFooter = () => {
  return (
    <Fragment>
      <div className="bg-white sm:py-5 py-3.5 border-t border-light-gray fixed bottom-0 w-full px-8 xl:ps-[18.75rem] flex flex-col items-center">
        <p className="text-sm font-medium text-center">
          2024 © All rights reserved. 
          <Link to="/" className="text-blue"> SpeedFame.fr </Link>
        </p>
        <label
          htmlFor="terms"
          className="text-sm font-medium text-center leading-none mt-2"
        >
          <Link to="/termsOfUse" className="text-blue">
            Conditions générales
          </Link>
        </label>
      </div>
    </Fragment>
  );
};

export default DashboardFooter;
