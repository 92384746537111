import React, { useState } from "react";
import AppLayout from "../../../components/applayout/AppLayout";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import interceptor from "../../../hooks/intercepter";
import useToken from "../../../hooks/useToken";
import Loader from "../../../components/ui/Loader";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const AddPackages = () => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [frequency, setFrequency] = useState(""); // Default frequency
  const [loading, setLoading] = useState(false);

  const { token } = useToken();
  const navigate = useNavigate();

  const handleSave = async () => {
    if (parseInt(frequency) < 1) {
      toast.error("Frequency must be greater than 0");
      return;
    }
    // if (parseInt(duration) < 1) {
    //   toast.error("Duration must be greater than 0");
    //   return;
    // }

    const data = {
      title: title,
      price: parseFloat(price),
      // content_duration: parseInt(duration),
      content_frequency: parseInt(frequency),
    };

    try {
      setLoading(true);
      const response = await interceptor.axiosPost("admin/package", data, "");
      setLoading(false);
      console.log(response.status);
      if (response.status === 200) {
        toast.success("Package Added Successfully");
        navigate("/admin/packages");
      } else {
        toast.error("Failed to add package");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add package");
      console.error("Error:", error);
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="sm:mb-24 mb-20">
          <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
            Add Package
          </h5>
          <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
            <div className="flex flex-col gap-5">
              <div className="bg-background rounded-xl sm:p-8 p-5">
                <label className="text-base font-medium">Package</label>
                <div className="flex flex-col gap-4 mt-5">
                  <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Duration in seconds"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </div> */}
                  <div>
                    <Input
                      type="number"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Frequency e.g 1,2,3.."
                      value={frequency}
                      min={1}
                      onChange={(e) => setFrequency(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <select
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                    >
                      <option value="once">Once</option>
                      <option value="repeated">Repeated</option>
                    </select>
                  </div> */}
                  <div>
                    <Button
                      size="sm"
                      className="w-full px-12"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </AppLayout>
  );
};

export default AddPackages;
