import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Components
import {
  DecorLines,
  Lightning,
  RhodeIsland,
  SoftStar,
} from "../../../../assets/icons/Icons";

// Assets
import heroImg from "../../../../assets/images/hero-img1.png"; // Update this path as needed

const Hero = () => {
  return (
    <Fragment>
      <section className="bg-yellow bg-opacity-5 md:py-14 py-8 relative overflow-hidden">
        <div className="md:container mx-auto px-5">
          <div className="flex flex-col items-center justify-center gap-5">
            <div className="relative">
              <RhodeIsland
                className="absolute xl:-left-8 lg:-left-1.5 md:-left-10 left-0 md:top-2 -top-8 lg:h-[4.5rem] md:h-14 h-8"
                width={45}
                height={72}
                fill="#D5FC71"
              />
              <SoftStar
                width={90}
                height={90}
                fill="#17FFD6"
                className="xl:w-[5.625rem] md:w-16 w-8 absolute md:bottom-1 bottom-[60%] xl:right-0 lg:-right-16 right-0"
              />
              <h1 className="xl:text-7xl lg:text-6xl md:text-5xl sm:text-3xl text-2xl font-playfair font-bold text-center">
                Boostez vos
                <span className="text-light-blue">&nbsp;ventes</span> et
                renforcez vos
                <span className="text-blue">&nbsp;liens</span> avec la vie de
                quartier
              </h1>
            </div>
            <p className="sm:text-2xl text-lg font-medium text-center">
              Transformez la découverte et le partage de contenu
            </p>
            <Link
              to="/contact-us"
              className="text-sm font-medium bg-gradient-primary rounded-xl px-5 py-3.5"
            >
              Demandez une démo
            </Link>
          </div>
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-6 md:pt-20 pt-10">
            <div className="lg:col-span-7 col-span-full relative">
              <img
                src={heroImg}
                className="rounded-xl block object-cover lg:w-auto w-full max-w-full"
                alt="Hero"
              />
              <Lightning
                className="absolute top-1/2 -translate-y-1/2 -left-8 md:h-28 h-20"
                fill="#23E3EE"
                width={65}
                height={115}
              />
            </div>
            <div className="lg:col-span-5 col-span-full">
              <div className="bg-gradient-primary rounded-xl md:py-10 py-6 sm:px-10 px-5 h-full flex flex-col justify-between lg:gap-0 gap-14">
                <div>
                  <h2 className="sm:text-4xl text-2xl font-playfair font-bold capitalize">
                    Notre promesse
                  </h2>
                  <p className="text-base font-medium leading-7 sm:mt-8 mt-5 ">
                    Nous vous promettons simplicité, sécurité, et dynamisme.
                    SpeedFame intègre des outils de pointe adaptés à votre
                    activité, vous permettant de rester un pas devant dans un
                    marché compétitif. Contactez-nous pour découvrir comment
                    nous pouvons vous aider à briller.
                  </p>
                </div>
                <div>
                  <Link
                    to="/contact-us"
                    className="inline-block text-sm font-medium bg-blue text-white rounded-xl px-7 py-3.5"
                  >
                    Contactez-nous !
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <DecorLines
            className="absolute bottom-0 left-[43%] -translate-x-1/2 -z-10" // Adjust left position to move slightly right
            fill="#242331"
            width={150}
            height={82}
          />
        </div>
      </section>
    </Fragment>
  );
};

export default Hero;
