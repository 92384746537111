import React from "react";

// Components
import Hero from "./components/hero/index";
import Feature from "./components/features";
import About from "./components/about";
import Partners from "./components/partners";
import Testimonials from "./components/testimonials";
import OurPrices from "./components/ourprices";
import Newsletter from "./components/newsletter";
import Layout from "../../components/layout/Layout";

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Feature />
      <About />
      <Partners />
      <Testimonials />
      <OurPrices />
      <Newsletter />
    </Layout>
  );
};

export default Home;
