import React, { Fragment } from "react";

// Components
import {
  ConnectIcon,
  EditIcon,
  LockIcon,
  MonitorIcon,
  SemiCircleYellow,
} from "../../../../assets/icons/Icons";

const Feature = () => {
  return (
    <Fragment>
      <section className="md:py-20 py-10 relative">
        <div className="md:container mx-auto px-5">
          <SemiCircleYellow
            fill="#D5FC71"
            width={80}
            height={220}
            className="absolute opacity-60 bottom-0 right-0 -z-10"
          />
          <h2 className="sm:text-4xl text-3xl font-playfair font-bold uppercase text-center ">
          Fonctionnalités
          </h2>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mt-12">
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <MonitorIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              Diffusion instantanée
              </h3>
              <p className="text-base ">
              Permet à vous et à vos clients de partager du contenu en direct, favorisant un engagement immédiat et personnalisé.
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <EditIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              Personnalisation
              </h3>
              <p className="text-base ">
              Créez et adaptez les contenus pour répondre aux attentes spécifiques de votre clientèle, offrant une expérience sur mesure.
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <ConnectIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              Connexion facile
              </h3>
              <p className="text-base ">
              Accédez et gérez vos campagnes publicitaires avec facilité, grâce à une plateforme intuitive compatible avec tous les écrans connectés.
              </p>
            </div>
            <div className="flex flex-col gap-5 bg-background rounded-xl sm:p-8 p-6 h-full">
              <LockIcon
                width={50}
                height={50}
                className="sm:w-[3.125rem] sm:h-[3.125rem] w-10 h-10"
                fill="#242331"
                fillLight="#503AC9"
              />
              <h3 className="text-2xl font-playfair font-bold capitalize">
              Sécurité

              </h3>
              <p className="text-base ">
              Profitez d'une tranquillité d'esprit avec un système sécurisé qui assure la protection des données et un contrôle total sur le contenu partagé.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Feature;
