import React, { Fragment, useRef, useState } from "react";

// Components
import { Button } from "../../components/ui/button";
import { InputOTP, InputOTPSlot } from "../../components/ui/input-otp";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../components/ui/dialog";
import interceptor from "../../hooks/intercepter";
import ResetPassword from "./ResetPassword";
import toast from "react-hot-toast";
const Otp = ({ isOpen, setIsOpen, email }) => {
  const [otp, setOtp] = React.useState(Array(6).fill(""));
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const [isResetOpen, setIsRestOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(email);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (index < refs.length - 1 && e.target.value !== "") {
      refs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move focus to the previous input field on backspace
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      refs[index - 1].current.focus();
    }
  };
  const handleSubmit = () => {
    debugger;
    const otpValue = otp.join("");
    console.log("OTP:", otpValue);
    setUserEmail(email);
    let modal = {
      email: email,
      otp: otpValue,
    };
    interceptor
      .axiosPost("users/auth/verify-otp", modal, null)
      .then((res) => {
        if (res.status === 200) {
          setIsOpen(false);
          setIsRestOpen(true);
        } else {
          toast.error("Incorrect Otp");
          console.log("error getting user : ", res);
        }
      })
      .catch((err) => {
        return err;
      });
    // Perform further actions like API calls with the OTP value
  };

  const handleResend = (values) => {
    interceptor
      .axiosPost("users/auth/forgot-password", { email: email }, null)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Resend Successfully");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  return (
    <Fragment>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger
          className="text-sm font-medium text-blue text-right"
          onClick={() => setIsOpen(true)}
        ></DialogTrigger>
        <DialogContent notDisable={true}  className="bg-white ms:px-7 px-5 py-5 rounded-xl sm:max-w-lg max-w-sm">
          <DialogDescription>
            <div className="flex flex-col gap-5 mt-10">
              <div className="flex flex-col gap-1">
                <h5 className="text-xl font-semibold capitalize">Enter Code</h5>
                <p className="text-sm">
                  We sent a code to&nbsp;
                  <span className="font-semibold">{email}</span>
                </p>
              </div>
              {/* <InputOTP maxLength={6} > */}
              <div className="flex justify-center gap-2">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    value={value}
                    ref={refs[index]}
                    maxLength={1}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onChange={(e) => handleChange(e, index)} // Assuming handleChange is a function to handle input changes
                    className={
                      ("relative flex flex-shrink-0 flex-grow-0 h-10 w-10 items-center justify-center border border-background text-sm transition-all",
                      "z-10 border border-blue text-blue bg-background rounded-xl sm:w-12 sm:h-12 w-10 h-10 sm:text-2xl text-xl font-semibold")
                    }
                    style={{ textAlign: "center" }}
                  />
                ))}
              </div>
              {/* </InputOTP> */}

              <p className="text-sm">
                Don’t receive the email?&nbsp;
                <div
                  onClick={handleResend}
                  className="font-semibold cursor-pointer text-blue"
                >
                  Click to resend
                </div>
              </p>
              <div>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  className="w-full md:px-12 px-9"
                >
                  Submit
                </Button>
              </div>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      {isResetOpen && (
        <ResetPassword
          isResetOpen={isResetOpen}
          setIsRestOpen={setIsRestOpen}
          userEmail={userEmail}
        />
      )}
    </Fragment>
  );
};

export default Otp;
