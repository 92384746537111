import React, { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";

// Components
import Sidebar from "../sidebar/Sidebar";
import Topbar from "../topbar/Topbar";
import DashboardFooter from "../dashboardfooter/DashboardFooter";

const AppLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <Fragment>
      <Sidebar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <Topbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <main className="xl:pl-[20.75rem] sm:pl-8 pl-5 sm:pr-8 pr-5 sm:pt-8 pt-5 transition-all duration-500 h-full">
        {children}
      </main>
      <DashboardFooter />
      <Outlet />
    </Fragment>
  );
};

export default AppLayout;
