import React, { useState } from "react";
import AppLayout from "../../../components/applayout/AppLayout";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import interceptor from "../../../hooks/intercepter";
import useToken from "../../../hooks/useToken";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/ui/Loader";

const EditPackages = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState("");

  const { token } = useToken();
  const [user, setUser] = useState(location.state?.user || {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleEditUser = async () => {
    if (parseInt(user.content_frequency) < 1) {
      toast.error("Frequency must be greater than 0");
      return;
    }
    if (parseInt(user.content_duration) < 1) {
      toast.error("Duration must be greater than 0");
      return;
    }

    try {
      setLoading(true);
      const response = await interceptor.axiosPut(
        "PATCH",
        `admin/package/${user.id}`,
        {
          title: user.title,
          price: parseFloat(user.price),
          content_duration: parseInt(user.content_duration),
          content_frequency: parseInt(user.content_frequency),
        },
        token
      );
      if (response.status === 200) {
        console.log("Package updated successfully");
        setLoading(false);

        navigate("/admin/packages");
        toast.success("Package Updated Successfully");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
      console.error("Failed to update package:", error);
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="sm:mb-24 mb-20">
          <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          Modifier le package
          </h5>
          <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
            <div className="flex flex-col gap-5">
              <div className="bg-background rounded-xl sm:p-8 p-5">
                <label className="text-base font-medium">Emballer</label>
                <div className="flex flex-col gap-4 mt-5">
                  <div>
                    <Input
                      type="text"
                      name="title"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Title"
                      value={user.title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      name="price"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Price"
                      value={user.price}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div>
                    <Input
                      type="text"
                      name="content_duration"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Duration"
                      value={user.content_duration}
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div>
                    <Input
                      type="text"
                      name="content_frequency"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Content Frequency"
                      value={user.content_frequency}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <Button
                      size="sm"
                      className="w-full px-12"
                      onClick={handleEditUser}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </AppLayout>
  );
};

export default EditPackages;
