import React from "react";

// Components
import Layout from "../../../components/layout/Layout";

// Assets
import thankyou from "../../../assets/images/thankyou-img.svg";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";

const Thankyou = () => {
  const pathSegments = window.location.pathname.split("/");
  const userId = pathSegments[3];
  const broadcasterId = pathSegments[4];

  const navigate = useNavigate();
  return (
    // <Layout>
    <>
      <Header />

      <section className="md:py-20 py-10">
        <div className="md:container mx-auto px-5">
          <div className="lg:w-6/12 w-full mx-auto">
            <div className="bg-background rounded-xl md:px-20 px-7 md:py-12 py-8 flex flex-col items-center gap-5">
              <img
                src={thankyou}
                className="block md:w-[12.5rem] w-[6.25rem] md:h-[12.5rem] h-[6.25rem]"
                alt=""
              />
              <h6 className="sm:text-xl text-base font-medium text-center">
                Voulez-vous ajouter un autre contenu ?
              </h6>
              <Button
                size="sm"
                className="w-fit px-6"
                onClick={() =>
                  navigate(`/advertiser/add-content/${userId}/${broadcasterId}`)
                }
              >
                Ajouter plus de contenu
              </Button>
            </div>
          </div>
        </div>
      </section>
      <div className="fixed bottom-0 w-full">
        <Footer />
      </div>
    </>
  );
};

export default Thankyou;
