import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Assets
import tewSemicircles from "../../../../assets/images/two-semicircles.svg";
const OurPrices = () => {
  return (
    <Fragment>
      <section className="md:py-20 py-10">
        <div className="md:container mx-auto px-5">
          <div className="flex lg:flex-row flex-col justify-center">
            <div className="lg:w-8/12 w-full">
              <div className="flex items-center justify-between flex-wrap">
                <h2 className="sm:text-4xl text-3xl font-playfair font-bold uppercase text-center ">
                  Nos prix
                </h2>
                <div>
                  <img
                    src={tewSemicircles}
                    width={150}
                    height={122}
                    className="block object-cover md:w-36 w-24"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex lg:flex-row flex-col items-center gap-6 md:mt-14 mt-10">
                <div className="lg:w-6/12 w-full">
                  <div className="bg-gradient-primary rounded-xl md:py-10 py-7 md:px-16 px-8 flex flex-col items-center justify-center sm:gap-8 gap-6">
                    <h4 className="md:text-[2rem] text-2xl font-playfair font-medium">
                      Gratuit ∞
                    </h4>
                    <h1 className="md:text-6xl text-4xl font-bold ">
                      0
                      <small className="text-base font-playfair font-semibold">
                        €
                      </small>
                    </h1>
                    <p className="text-base font-medium text-center">
                      Découvrez la facilité et l'efficacité de SpeedFame sans
                      frais.
                    </p>
                    <Link
                      to="/contact-us"
                      className="text-sm font-playfair font-medium text-white bg-blue rounded-xl px-7 py-3"
                    >
                      Commencer
                    </Link>
                  </div>
                </div>
                <div className="lg:w-6/12 w-full">
                  <div className="bg-gradient-primary rounded-xl md:py-10 py-7 md:px-16 px-8 flex flex-col items-center justify-center sm:gap-8 gap-6">
                    <h4 className="md:text-[2rem] text-2xl font-playfair font-medium">
                      Premium
                    </h4>
                    <h1 className="md:text-6xl text-4xl font-bold ">
                      39.9
                      <small className="text-base font-playfair font-semibold">
                        €/mois
                      </small>
                    </h1>
                    <p className="text-base font-medium text-center">
                      Exploitez tout le potentiel de SpeedFame pour maximiser
                      votre engagement client.
                    </p>
                    <Link
                      to="/contact-us"
                      className="text-sm font-playfair font-medium text-white bg-blue rounded-xl px-7 py-3"
                    >
                      Accélérer
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default OurPrices;
