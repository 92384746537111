import React, { useState, useEffect, Fragment } from "react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import AppLayout from "../../../components/applayout/AppLayout";
import useToken from "../../../hooks/useToken"; // Import the useToken hook
import interceptor from "../../../hooks/intercepter.js"; // Interceptor
import { PhoneNumberInput } from "../../../components/ui/phoneInput";
import { baseUrl } from "../../../hooks/intercepter.js";
import { z } from "zod"; // Import Zod
import { useForm } from "react-hook-form"; // Import React Hook Form
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "../../../components/ui/form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// Define validation schema using Zod
const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, { message: "Location name is required" }),
  location_address: z
    .string()
    .min(1, { message: "Location address is required" }),
});

const Settings = () => {
  const { user, token } = useToken();
  const [qrCodeUrl, setQrCodeUrl] = useState(null); // State to store QR code URL
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      location_name: "",
      location_address: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("first_name", user.first_name || "");
      setValue("last_name", user.last_name || "");
      setValue("email", user.email || "");
      setValue("phone", user.phone || "");
      setValue("location_name", user.location_name || "");
      setValue("location_address", user.location_address || "");
    }
  }, [user, setValue]);

  useEffect(() => {
    if (user) {
      fetchQrCode(); // Fetch QR code when component mounts or user updates
    }
  }, [user]);

  const fetchQrCode = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/broadcaster/${user.id}/qrcode`,
        token
      );

      if (response.data && response.data.data && response.data.data.filename) {
        const filename = response.data.data.filename;
        setQrCodeUrl(baseUrl + `media/${filename}`);
      } else {
        throw new Error("Filename is missing in the response data.");
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
      toast.error("Failed to fetch QR code. Please try again.");
    }
  };

  const onSubmit = async (data) => {
    try {
      const response = await interceptor.axiosPut(
        "PATCH",
        "users/me",
        data,
        token
      );
      console.log("User updated:", response.data);
      const updatedUser = {
        ...user,
        ...data,
      };
      localStorage.setItem("@tokenData", JSON.stringify(updatedUser));
      toast.success("User information updated successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user information. Please try again.");
    }
  };

  return (
    <AppLayout>
      <section className="sm:mb-24 mb-20">
        <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
        Paramètres
        </h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-7 sm:my-8 my-6"
        >
          <div className="grid md:grid-cols-3 grid-cols-1 gap-7">
            <div>
              <FormField
                control={control}
                name="first_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="first_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="First Name"
                    />
                    {errors.first_name && (
                      <p className="text-sm text-red">
                        {errors.first_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="last_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="last_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Last Name"
                    />
                    {errors.last_name && (
                      <p className="text-sm text-red">
                        {errors.last_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="email"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Email"
                      disabled={true}
                    />
                    {errors.email && (
                      <p className="text-sm text-red">{errors.email.message}</p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <Fragment>
                    <PhoneNumberInput
                      type="text"
                      name="phone"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Phone"
                    />
                    {errors.phone && (
                      <p className="text-sm text-red">{errors.phone.message}</p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="location_name"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="location_name"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Location Name"
                    />
                    {errors.location_name && (
                      <p className="text-sm text-red">
                        {errors.location_name.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
            <div>
              <FormField
                control={control}
                name="location_address"
                render={({ field }) => (
                  <Fragment>
                    <Input
                      type="text"
                      name="location_address"
                      {...field}
                      className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Location Address"
                    />
                    {errors.location_address && (
                      <p className="text-sm text-red">
                        {errors.location_address.message}
                      </p>
                    )}
                  </Fragment>
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            {qrCodeUrl && (
              <img
                src={qrCodeUrl}
                alt="Content"
                className="my-4"
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "200px",
                  maxHeight: "200px",
                }}
              />
            )}
          </div>
          <div className="flex justify-center">
            <Button size="sm" type="submit" className="w-fit px-12">
            Modifier
            </Button>
          </div>
        </form>
      </section>
    </AppLayout>
  );
};

export default Settings;
