import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../../../components/applayout/AppLayout";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../hooks/intercepter.js";
import Loader from "../../../components/ui/Loader";
import toast from "react-hot-toast";
import { PhoneNumberInput } from "../../../components/ui/phoneInput";
import { z } from "zod"; // Import Zod
import { useForm } from "react-hook-form"; // Import React Hook Form
import { zodResolver } from "@hookform/resolvers/zod"; // Import Zod Resolver
import { FormField } from "../../../components/ui/form"; // Import FormField component

// Define validation schema using Zod
const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, { message: "Location name is required" }),
  location_address: z
    .string()
    .min(1, { message: "Location address is required" }),
});

const EditUser = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useToken();
  const [user, setUser] = useState(location.state?.user || {});

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      defaultValues: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        location_name: "",
        location_address: "",
      },
    },
  });

  useEffect(() => {
    if (user) {
      setValue("first_name", user.first_name || "");
      setValue("last_name", user.last_name || "");
      setValue("email", user.email || "");
      setValue("phone", user.phone || "");
      setValue("location_name", user.location_name || "");
      setValue("location_address", user.location_address || "");
    }
  }, [user, setValue]);

  const handleEditUser = async (data) => {
    try {
      setLoading(true);
      const response = await interceptor.axiosPut(
        "PATCH",
        `users/${user.id}`,
        {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone ? data.phone : "",
          location_name: data.location_name,
          location_address: data.location_address,
        },
        token
      );
      if (response.status === 200) {
        console.log("User updated successfully");
        setLoading(false);
        toast.success("User Updated Successfully");
        navigate("/admin/users");
      } else {
        setLoading(false);
        toast.error(
          response.message.includes(")")
            ? response.message.split(")")[1]
            : response.message
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.message || "Failed to update user");
      console.error("Failed to update user:", error);
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="sm:mb-24 mb-20">
          <div className="sm:mt-8 mt-6">
            <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
              Edit user
            </h5>
            <form
              onSubmit={handleSubmit(handleEditUser)}
              className="flex flex-col gap-7 sm:mt-8 mt-6"
            >
              <div className="grid md:grid-cols-3 grid-cols-1 gap-7">
                <div>
                  <FormField
                    control={control}
                    name="first_name"
                    render={({ field }) => (
                      <Fragment>
                        <Input
                          type="text"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="First Name"
                        />
                        {errors.first_name && (
                          <p className="text-sm text-red">
                            {errors.first_name.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={control}
                    name="last_name"
                    render={({ field }) => (
                      <Fragment>
                        <Input
                          type="text"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="Last Name"
                        />
                        {errors.last_name && (
                          <p className="text-sm text-red">
                            {errors.last_name.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Fragment>
                        <Input
                          type="text"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="Email"
                          disabled={true}
                        />
                        {errors.email && (
                          <p className="text-sm text-red">
                            {errors.email.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={control}
                    name="phone"
                    render={({ field }) => (
                      <Fragment>
                        <PhoneNumberInput
                          type="text"
                          name="phone"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="Phone"
                        />
                        {errors.phone && (
                          <p className="text-sm text-red">
                            {errors.phone.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={control}
                    name="location_name"
                    render={({ field }) => (
                      <Fragment>
                        <Input
                          type="text"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="Location Name"
                        />
                        {errors.location_name && (
                          <p className="text-sm text-red">
                            {errors.location_name.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
                <div>
                  <FormField
                    control={control}
                    name="location_address"
                    render={({ field }) => (
                      <Fragment>
                        <Input
                          type="text"
                          {...field}
                          className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                          placeholder="Location Address"
                        />
                        {errors.location_address && (
                          <p className="text-sm text-red">
                            {errors.location_address.message}
                          </p>
                        )}
                      </Fragment>
                    )}
                  />
                </div>
              </div>
              <div>
                <Button size="sm" className="w-fit px-12" type="submit">
                  Edit User
                </Button>
              </div>
            </form>
          </div>
        </section>
      )}
    </AppLayout>
  );
};

export default EditUser;
