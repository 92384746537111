import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Components
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from "../../assets/icons/Icons";

// Assets
import logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <Fragment>
      <div className="bg-dark sm:py-7 py-5">
        <div className="md:container mx-auto px-5">
          <div className="flex sm:flex-row flex-col justify-between sm:items-start items-center gap-6">
            <Link to="/">
              <img
                src={logo}
                width={200}
                height={23}
                className="block object-cover sm:w-48 w-36"
                alt=""
              />
            </Link>
            <label
              htmlFor="terms"
              className="text-sm font-medium text-center leading-none mt-2"
            >
              <Link to="/termsOfUse" className="text-blue">
              Conditions générales
              </Link>
            </label>
            <ul className="flex items-center gap-2.5">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61558927477883&is_tour_dismissed"
                  className="bg-facebook w-6 h-6 rounded-md flex items-center justify-center"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Facebook stroke="white" width={9} height={13} />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/speedfame"
                  className="bg-twitter w-6 h-6 rounded-md flex items-center justify-center"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Twitter fill="white" width={10} height={10} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/103530999/admin/feed/following/"
                  className="bg-linkedin w-6 h-6 rounded-md flex items-center justify-center"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Linkedin stroke="white" width={14} height={14} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/speedfame_app?igsh=ZHhrdTZ5d3J3dnBi&utm_source=qr"
                  className="bg-instagram w-6 h-6 rounded-md flex items-center justify-center"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Instagram stroke="white" width={12} height={12} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
