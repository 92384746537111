import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// Assets
import logo from "../../assets/images/logo.png";
import AuthDialog from "../../pages/auth/components/authdialog/index";

const Header = () => {
  return (
    <Fragment>
      <header className="bg-white border-b border-background py-3.5 sticky top-0 z-[2] w-full">
        <div className="md:container mx-auto px-5">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img
                src={logo}
                width={200}
                height={23}
                className="block object-cover sm:w-[12.5rem] w-[6.5rem]"
                alt=""
              />
            </Link>
            <div className="flex items-center sm:gap-12 gap-4">
              <Link
                to="/contact-us"
                className="sm:text-base text-xs font-medium p-1 transition-all duration-500 hover:text-blue"
              >
                Nous contacter
              </Link>
              {/* <Link
                to="/"
                className="bg-gradient-primary rounded-xl text-sm font-medium sm:py-3.5 py-2.5 sm:px-5 px-3"
              >
                My Account
              </Link> */}
              <AuthDialog />
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
