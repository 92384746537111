import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../../../components/applayout/AppLayout";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../hooks/intercepter.js";
import Loader from "../../../components/ui/Loader";
import toast from "react-hot-toast";
import { PhoneNumberInput } from "../../../components/ui/phoneInput";

const ViewUser = () => {
  const [loading, setLoading] = useState("");
  const [revenue, setRevenue] = useState("");
  const location = useLocation();
  const { token } = useToken();
  const [user, setUser] = useState(location.state?.user || {});
  const [count, setCount] = useState(null);
  console.log(user?.id);
  useEffect(() => {
    fetchContentCount();
  }, []);
  const fetchContentCount = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/broadcasters/${user.id}/content-count`,
        token
      );
      if (response.status === 200) {
        setCount(response?.data?.data);
        console.log(response?.data?.data.content_count);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRevenue = async (date) => {
    debugger
    let val = date.split("-")
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/broadcaster-revenue/${user.id}?month=${val[1]}&year=${val[0]}`,
        token
      );
      if (response.status === 200) {
        setRevenue(response?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="sm:mb-24 mb-20">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7">
            <div className="bg-background rounded-xl md:px-7 px-4 md:py-[2.125rem] py-7 flex md:flex-row flex-col-reverse items-center justify-between md:gap-0 gap-5 h-full">
              <p className="md:text-base text-sm md:text-start text-center font-medium">
                Number of contents uploaded
              </p>
              <h3 className="md:text-4xl text-2xl font-bold px-4">{count?.content_count}</h3>
            </div>
            <div className="bg-background rounded-xl md:px-7 px-4 md:py-[2.125rem] py-7 flex md:flex-row flex-col-reverse items-center justify-between md:gap-0 gap-5 h-full">
              <p className="md:text-base text-sm md:text-start text-center font-medium">
                Number of times when the broadcaster clicks on the broadcast
                now.
              </p>
              <h3 className="md:text-4xl text-2xl font-bold px-4">{count?.broadcast_count}</h3>
            </div>
            <div className="bg-background rounded-xl md:px-7 px-4 md:py-[2.125rem] py-7 flex md:flex-row flex-col-reverse items-center justify-between md:gap-6 gap-5 h-full">
              <p className="md:text-base text-sm md:text-start text-center font-medium">
                Number of times when the broadcaster left the broadcast now
                page.
              </p>
              <h3 className="md:text-4xl text-2xl font-bold px-4">{count?.cancel_count}</h3>
            </div>
          </div>

          <div className="sm:mt-8 mt-6">
            <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
              Monthly revenue
            </h5>
            <div className="flex flex-col gap-7 sm:mt-8 mt-6">
              <div className="grid md:grid-cols-3 grid-cols-1 gap-7 ">
                <div>
                  <Input
                    type="month"
                    name="revenue"
                    onChange={(e) => {
                      debugger
                      getRevenue(e.target.value)
                    }}
                    style={{ display: "block" }}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-3 grid-cols-1 gap-7 ">
                <div className="bg-background rounded-xl md:px-7 px-4 md:py-[2.125rem] py-7 flex md:flex-row flex-col-reverse items-center justify-between md:gap-6 gap-5 h-full">
                  <p className="md:text-base text-sm md:text-start text-center font-medium">
                    Monthly revenue
                  </p>
                  <h3 className="md:text-4xl text-2xl font-bold px-4">{revenue?.total_revenue ? revenue.total_revenue.toFixed(2) : ''}$</h3>
                </div>
              </div>

            </div>
          </div>

          <div className="sm:mt-8 mt-6">
            <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
              User Details
            </h5>
            <div className="flex flex-col gap-7 sm:mt-8 mt-6">
              <div className="grid md:grid-cols-3 grid-cols-1 gap-7 ">
                <div>
                  <Input
                    type="text"
                    name="first_name"
                    value={user.first_name}
                    disabled={true}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="First Name"
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="last_name"
                    value={user.last_name}
                    disabled={true}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="Last Name"
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="email"
                    value={user.email}
                    disabled={true}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <PhoneNumberInput
                    type="text"
                    name="phone"
                    value={user.phone}
                    disabled={true}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="location_name"
                    value={user.location_name || ""}
                    disabled={true}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="Location Name"
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="location_address"
                    value={user.location_address || ""}
                    className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                    placeholder="Location Address"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </AppLayout>
  );
};

export default ViewUser;
