import React, { useEffect, useState } from "react";

// Components
import { Info } from "../../../../../src/assets/icons/Icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { cn } from "../../../../../src/lib/utils";
import interceptor from "../../../../hooks/intercepter";

// Assets
import pkgImg1 from "../../../../assets/images/pkg-img1.png";
import pkgImg2 from "../../../../assets/images/pkg-img2.png";
import pkgImg3 from "../../../../assets/images/pkg-img3.png";

const StepTwo = (prop) => {
  const { onSelectedPackage, duration, seleted } = prop
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handlePackageSelect = (value) => {
    let pkg = packages.find((p) => p.id == value)
    setErrorMessage('')
    setSelectedPackage('');
    onSelectedPackage('')
    if (duration > pkg.content_duration) {
      setError(true);
      setErrorMessage(
        `Please select a video that is no longer than ${pkg.content_duration} seconds.`
      );
    }
    else {
      setSelectedPackage(value);
      onSelectedPackage(value)
    }

  };

  useEffect(() => {
    if (packages?.length == 0) {
      fetchPackages();
    }
    // if (!selectedPackage) setSelectedPackage(seleted ? seleted : '');
  }, [packages]);


  const fetchPackages = async () => {
    try {
      interceptor.axiosGet("get", `admin/package`).then((response) => {
        if (response.status === 200) {
          setPackages(response?.data?.data?.result);
        }
      })
    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  };

  const isDisabled = (value) => selectedPackage && selectedPackage !== value;
  return (
    <>
      <section>
        <div className="md:container mx-auto px-5">
          <div className="lg:w-6/12 w-full mx-auto">
            <h4 className="md:text-3xl text-2xl text-dark font-bold text-center">
              Combien de diffusions vous conviennent ?
            </h4>
            <form>
              <div className="flex flex-col gap-5 items-center justify-center mt-10 w-full">
                {packages && packages.map((pkg, i) =>
                (
                  <>
                    {i == 0 ?
                      <label
                        className={cn(
                          "cursor-pointer w-full transition-all duration-700",
                          selectedPackage === pkg.id ? "transition-all duration-700 scale-[1.05]" : "",
                          isDisabled(pkg.id)
                            ? "filter grayscale-[1]"
                            : ""
                        )}
                      >
                        <input
                          type="radio"
                          name="package"
                          value={pkg.id}
                          checked={selectedPackage === pkg.id}
                          onChange={() => handlePackageSelect(pkg.id)}
                          className="hidden"
                        />
                        <div className="flex items-start gap-1 w-full">
                          <div className={i == 0 ? "bg-thin-blue border-2 border-dark-blue rounded-xl px-4 py-3 w-full"
                            : i == 1 ? "bg-light-orange border-2 border-orange rounded-xl px-4 py-3 w-full"
                              : "bg-light-green border-2 border-green rounded-xl px-4 py-3 w-full"}>

                            <div className="flex items-center gap-4">
                              <img
                                src={pkgImg1}
                                width={77}
                                height={86}
                                className="block object-contain w-[6.25rem] h-[6.25rem] flex-shrink-0 flex-grow-0"
                                alt=""
                              />
                              <div className="flex flex-col">
                                <h6 className="sm:text-lg text-sm font-bold text-dark-blue">
                                  {pkg.title}
                                </h6>
                                <small className="text-xs font-semibold block mt-1 text-dark-blue">
                                  {pkg.content_frequency} Diffusion
                                </small>
                                <p className="text-sm text-dark-blue mt-3">
                                  Votre contenu ne sera diffusé qu'une seule fois pendant {pkg.content_duration} secondes sur l'écran.
                                </p>
                              </div>
                            </div>
                            <div>
                              <strong className="sm:text-base text-sm font-bold text-dark block text-right">
                                {pkg.price}€
                              </strong>
                            </div>
                          </div>
                          {/* <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Info
                                  stroke="#242331"
                                  className="flex-shrink-0 flex-grow-0"
                                  width={16}
                                  height={16}
                                />
                              </TooltipTrigger>
                              <TooltipContent className="bg-blue text-white border-none">
                                <p>Add something</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider> */}
                        </div>
                      </label>
                      : i == 1 ?
                        <label
                          className={cn(
                            "cursor-pointer w-full transition-all duration-700",
                            selectedPackage === pkg.id
                              ? "transition-all duration-700 scale-[1.05]"
                              : "",
                            isDisabled(pkg.id)
                              ? "filter grayscale-[1]"
                              : ""
                          )}
                        >
                          <input
                            type="radio"
                            name="package"
                            value={pkg.id}
                            checked={selectedPackage === pkg.id}
                            onChange={() => handlePackageSelect(pkg.id)}
                            className="hidden"
                          />
                          <div className="flex items-start gap-1 w-full">
                            <div className="bg-light-orange border-2 border-orange rounded-xl px-4 py-3 w-full">
                              <div className="flex items-center gap-4">
                                <img
                                  src={pkgImg2}
                                  width={77}
                                  height={86}
                                  className="block object-contain w-[6.25rem] h-[6.25rem] flex-shrink-0 flex-grow-0"
                                  alt=""
                                />
                                <div className="flex flex-col">
                                  <h6 className="sm:text-lg text-sm font-bold text-orange">
                                    {pkg.title}
                                  </h6>
                                  <small className="text-xs font-semibold block mt-1 text-orange">
                                    {pkg.content_frequency} Diffusions
                                  </small>
                                  <p className="text-sm text-orange mt-3">
                                    Votre contenu sera lu {pkg.content_frequency} fois pendant {pkg.content_duration} secondes
                                    à chaque fois sur l'écran.
                                  </p>
                                </div>
                              </div>
                              <div>
                                <strong className="sm:text-base text-sm font-bold text-dark block text-right">
                                  {pkg.price}€
                                </strong>
                              </div>
                            </div>
                            {/* <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Info
                                    stroke="#242331"
                                    className="flex-shrink-0 flex-grow-0"
                                    width={16}
                                    height={16}
                                  />
                                </TooltipTrigger>
                                <TooltipContent className="bg-blue text-white border-none">
                                  <p>Add something</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider> */}
                          </div>
                        </label>

                        : i == 2 ?
                          <label
                            className={cn(
                              "cursor-pointer w-full transition-all duration-700",
                              selectedPackage === pkg.id
                                ? "transition-all duration-700 scale-[1.05]"
                                : "",
                              isDisabled(pkg.id)
                                ? "filter grayscale-[1]"
                                : ""
                            )}
                          >
                            <input
                              type="radio"
                              name="package"
                              value={pkg.id}
                              checked={selectedPackage === pkg.id}
                              onChange={() => handlePackageSelect(pkg.id)}
                              className="hidden"
                            />
                            <div className="flex items-start gap-1 w-full">
                              <div className="bg-light-green border-2 border-green rounded-xl px-4 py-3 w-full">
                                <div className="flex items-center gap-4">
                                  <img
                                    src={pkgImg3}
                                    width={77}
                                    height={86}
                                    className="block object-contain w-[6.25rem] h-[6.25rem] flex-shrink-0 flex-grow-0"
                                    alt=""
                                  />
                                  <div className="flex flex-col">
                                    <h6 className="sm:text-lg text-sm font-bold text-green">
                                      {pkg.title}
                                    </h6>
                                    <small className="text-xs font-semibold block mt-1 text-green">
                                      {pkg.content_frequency} Diffusions
                                    </small>
                                    <p className="text-sm text-green mt-3">
                                      Votre contenu sera lu {pkg.content_frequency} fois pendant {pkg.content_duration} secondes
                                      à chaque fois sur l'écran.
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <strong className="sm:text-base text-sm font-bold text-dark block text-right">
                                    {pkg.price}€
                                  </strong>
                                </div>
                              </div>
                              {/* <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Info
                                      stroke="#242331"
                                      className="flex-shrink-0 flex-grow-0"
                                      width={16}
                                      height={16}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent className="bg-blue text-white border-none">
                                    <p>Add something</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider> */}
                            </div>
                          </label>
                          :
                          <label
                            className={cn(
                              "cursor-pointer w-full transition-all duration-700",
                              selectedPackage === pkg.id
                                ? "transition-all duration-700 scale-[1.05]"
                                : "",
                              isDisabled(pkg.id)
                                ? "filter grayscale-[1]"
                                : ""
                            )}
                          >
                            <input
                              type="radio"
                              name="package"
                              value={pkg.id}
                              checked={selectedPackage === pkg.id}
                              onChange={() => handlePackageSelect(pkg.id)}
                              className="hidden"
                            />
                            <div className="flex items-start gap-1 w-full">
                              <div className={"bg-light-red border-2 border-red rounded-xl px-4 py-3 w-full"}>

                                <div className="flex items-center gap-4">
                                  <img
                                    src={pkgImg1}
                                    width={77}
                                    height={86}
                                    className="block object-contain w-[6.25rem] h-[6.25rem] flex-shrink-0 flex-grow-0"
                                    alt=""
                                  />
                                  <div className="flex flex-col">
                                    <h6 className="sm:text-lg text-sm font-bold text-dark">
                                      {pkg.title}
                                    </h6>
                                    <small className="text-xs font-semibold block mt-1 text-dark">
                                      {pkg.content_frequency} Diffusions
                                    </small>
                                    <p className="text-sm text-dark mt-3">
                                      Votre contenu sera lu {pkg.content_frequency} fois pendant {pkg.content_duration} secondes
                                      à chaque fois sur l'écran.
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <strong className="sm:text-base text-sm font-bold text-dark block text-right">
                                    {pkg.price}€
                                  </strong>
                                </div>
                              </div>
                              {/* <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Info
                                      stroke="#242331"
                                      className="flex-shrink-0 flex-grow-0"
                                      width={16}
                                      height={16}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent className="bg-blue text-white border-none">
                                    <p>Add something</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider> */}
                            </div>
                          </label>
                    }

                  </>
                )
                )}

              </div>
            </form>

            <p>{errorMessage}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepTwo;
