import React from "react";

// Components
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const Terms = () => {
  return (
    <>
      <Header />

      <section className="md:py-20 py-10">
        <div className="md:container mx-auto px-5">
          <div className="lg:w-10/12 w-full mx-auto text-center font-medium mb-16">
            {/* <div className="bg-background rounded-xl md:px-20 px-7 md:py-12 py-8 flex flex-col items-center gap-5">
              <img
                src={thankyou}
                className="block md:w-[12.5rem] w-[6.25rem] md:h-[12.5rem] h-[6.25rem]"
                alt=""
              />
              <h6 className="sm:text-xl text-base font-medium text-center">
                Would you like to add another content?" if yes, the same flow
                starts again.
              </h6>
              <Button
                size="sm"
                className="w-fit px-6"
                onClick={() => navigate(`/advertiser/add-content/${userId}`)}
              >
                Add more content
              </Button>
            </div> */}
            <h1 className="font-bold text-xl text-blue">
              CONDITIONS GÉNÉRALES D'UTILISATION ET ACCORD DE LICENCE DE L'UTILISATEUR FINAL
            </h1>
            <h5 className="text-blue">Dernière mise à jour : 29 avril 2024</h5>
            <p className="my-5">
              L'utilisation des sites Web, des applications mobiles,
              des plateformes et des autres services sur lesquels ces conditions sont présentes
              (collectivement, la "Plateforme"), y compris les fonctionnalités et services mis à
              disposition via cette Plateforme, est soumise aux présentes conditions d'utilisation
              ("Conditions"). Veuillez lire attentivement ces Conditions avant d'utiliser cette
              Plateforme. La Plateforme est détenue ou contrôlée par SpeedFame
              ("Société", "Nous", "nous", "Vous" ou "nous").
              <br />
              <br />
              Les Conditions forment un accord légalement contraignant entre vous et SpeedFame. Aux fins des présentes Conditions, "vous" et "votre" signifient vous en tant qu'utilisateur de la Plateforme. Cette Plateforme est destinée et applicable, âgés de 18 ans ou plus. Si vous êtes en dessous de l'âge limite fixé pour votre juridiction, vous ne pouvez pas utiliser cette Plateforme. Si vous avez moins de 18 ans, vous ne pouvez utiliser la Plateforme que si vous êtes résident de la France et avec le consentement de votre parent ou tuteur légal. Veuillez vous assurer que votre parent ou tuteur légal a examiné et discuté de ces Conditions avec vous.
            </p>
            <p>
              EN ACCÉDANT À CETTE PLATEFORME DE QUELQUE MANIÈRE QUE CE SOIT,
              Y COMPRIS, SANS LIMITATION, EN LA PARCOURANT,
              EN UTILISANT TOUTE INFORMATION ET/OU EN SOUMETTANT DES INFORMATIONS À LA SOCIÉTÉ,
              LÀ OÙ LA LOI LE PERMET, VOUS ACCEPTEZ ET ÊTES LÉGALEMENT LIÉ PAR LES TERMES, CONDITIONS,
              POLITIQUES ET AVIS CONTENUS DANS LES PRÉSENTES CONDITIONS, Y COMPRIS, SANS LIMITATION,
              LA RÉALISATION DE TRANSACTIONS ÉLECTRONIQUEMENT, LES DÉSAVEUX DE GARANTIES,
              LES EXCLUSIONS ET LIMITATIONS DE DOMMAGES ET DE RECOURS,L'ARBITRAGE CONTRAIGNANT ET LE CHOIX DU DROIT FRANÇAIS. <br />
              <br />
              De temps à autre, nous pouvons mettre à jour cette Plateforme et ces Conditions. Votre utilisation continue de cette Plateforme après que nous ayons publié des modifications apportées à ces Conditions constitue votre accord à ces modifications. Si vous n'acceptez pas les nouvelles Conditions, vous devez cesser d'accéder ou d'utiliser la Plateforme. Vous acceptez de consulter ces Conditions périodiquement pour vous assurer que vous êtes familiarisé avec la version la plus récente. Nous utiliserons des efforts commercialement raisonnables pour vous informer en cas de modifications importantes apportées à ces Conditions, comme l'exige la loi applicable. En outre, lors de l'utilisation de services particuliers, vos transactions ou services peuvent être soumis à des conditions et des conditions supplémentaires applicables à ces services, dont nous vous informerons. Cela peut inclure des promotions, des concours, ainsi que des politiques de retour pour certaines transactions. <br />
              <br />
              L'accès à certains services de la Plateforme ou à certaines fonctionnalités de la Plateforme (tels que, à titre d'exemple et sans limitation, la capacité de soumettre ou de partager du contenu utilisateur (défini ci-dessous)) peut être soumis à des restrictions d'âge et ne pas être disponible pour tous les utilisateurs de la Plateforme. Notre Plateforme est fournie pour une utilisation privée, non commerciale.
              <br />
              <br />


              Nous pouvons interrompre cette Plateforme ou toute partie de celle-ci, avec ou sans préavis, ou empêcher votre utilisation de cette Plateforme avec ou sans préavis. Sauf interdiction par la loi, vous acceptez que vous n'avez aucun droit dans cette Plateforme et que la Société n'aura aucune responsabilité envers vous si cette Plateforme est interrompue ou si votre capacité à accéder à la Plateforme ou à tout contenu que vous avez pu soumettre via la Plateforme est résiliée. Toutes les restrictions, droits accordés par
              <br />
              vous et toutes les renonciations et limitations de responsabilité survivront à toute résiliation.
              <br />
              <br />
              <span className="text-blue font-semibold">1. CONTENU DE LA SOCIÉTÉ</span>
              <br />
              <br />
              Le contenu de cette Plateforme qui est fourni par la Société ou ses concédants de licence, y compris tous les matériaux qui sont inclus dans ou sont autrement une partie de la Plateforme (y compris les versions passées, présentes et futures de la Plateforme), y compris, sans limitation : contenu ; logiciels ; graphiques ; illustrations ; mise en page ; texte ; instructions ; photographies, images ; audio ; vidéos ; musique ou son ; conceptions ; marques de commerce, marques de service, habillage commercial, logos, noms de produits ou d'emballages et slogans ; tout matériel pouvant faire l'objet d'un droit d'auteur (y compris le code source et le code objet) ; l'"apparence et la convivialité" de la Plateforme ; brevets ; fichiers numériques téléchargeables, et la compilation de tout ce qui précède ("Contenu de la Société") est la propriété de la Société et de ses concédants de licence, et est protégé en France et à l'international en vertu des lois sur les marques de commerce, le droit d'auteur et autres lois sur la propriété intellectuelle. Nonobstant ce qui précède, vous ou vos concédants de licence serez propriétaire de tout contenu utilisateur (tel que défini ci-dessous) que vous téléchargez ou transmettez via la Plateforme.{" "}
              <br />
              <br />
              Vous acceptez de ne pas télécharger, reproduire, copier, distribuer, transmettre, diffuser, afficher, vendre, concéder sous licence ou utiliser autrement de quelque manière que ce soit le Contenu de la Société (a) dans des publications, (b) dans des représentations publiques, (c) sur des sites Web, des applications, des plateformes ou dans des services autres que cette Plateforme à quelque fin que ce soit, (d) en relation avec des produits ou services qui ne sont pas ceux de la Société, et/ou (e) de toute autre manière susceptible de provoquer la confusion chez les consommateurs, de dénigrer ou de discréditer la Société et/ou ses concédants de licence ou leurs produits ou marques respectifs, de diluer la force de la propriété intellectuelle de la Société ou de ses concédants de licence, ou d'enfreindre autrement les droits de propriété intellectuelle de la Société ou de ses concédants de licence. Vous acceptez en outre de ne pas utiliser de quelque autre manière que ce soit le Contenu de la Société ou le contenu de tiers qui apparaît sur cette Plateforme. Nous et nos concédants de licence nous réservons tous les droits non expressément accordés dans et sur le Contenu de la Société.
              <br />
              <br />
              <span className="text-blue font-semibold">
                2. UTILISATION DE LA PLATEFORME ET POLITIQUE DE PUBLICATION
              </span>
              <br />
              <br />
              Sous réserve des présentes Conditions, vous bénéficiez par les présentes d'une licence non exclusive, limitée, non transférable, non sous-licenciable, révocable, mondiale d'accès et d'utilisation de la Plateforme, y compris de téléchargement de la Plateforme sur un appareil autorisé, et d'accès au Contenu de la Société uniquement pour votre usage personnel, non commercial via votre utilisation de la Plateforme et uniquement en conformité avec les présentes Conditions. La Société se réserve tous les droits non expressément accordés dans la Plateforme et le Contenu de la Société. Vous reconnaissez et acceptez que la Société peut résilier cette licence à tout moment pour quelque raison que ce soit ou sans raison.  <br />
              <br />
              Les exigences suivantes s'appliquent à votre utilisation de la Plateforme, y compris toute soumission, texte, photographies, vidéo, enregistrements sonores, données, commentaires ou autres matériaux fournis, soumis, publiés, transmis ou affichés par vous en relation avec la Plateforme ("Contenu Utilisateur") : vous ne devez pas accéder à cette Plateforme si vous êtes incapable d'accepter légalement les Conditions ; vous n'avez pas l'autorisation de votre parent ou tuteur légal d'accéder à la Plateforme si vous avez moins de 18 ans en France ; vous ne devez pas utiliser de fonctionnalité de communication électronique de la Plateforme à des fins illégales, tortueuses, abusives, intrusives dans la vie privée d'autrui, harcelantes, diffamatoires, calomnieuses, embarrassantes, obscènes, menaçantes, haineuses, envahissantes de la vie privée d'une autre personne, abusives, racistes, portant atteinte aux droits de propriété intellectuelle, pornographiques, violentes ou autrement répréhensibles ou inappropriées, telles que déterminées par la Société ; vous ne devez pas télécharger, publier, reproduire ou distribuer d'informations, logiciels ou autres matériels protégés par des droits d'auteur ou tout autre droit de propriété intellectuelle (ainsi que les droits de publicité et de confidentialité) sans avoir au préalable obtenu l'autorisation du propriétaire de ces droits ; vous ne devez pas collecter, stocker ou publier de contenu qui : contient des informations personnelles sur d'autres utilisateurs ou sur un individu quelconque ; porte atteinte à la vie privée/à la publicité d'un individu ou d'une entité quelconque.  <br />
              <br />
              Vous et Speed Fame convenez que toute controverse ou réclamation (à l'exception de toute réclamation d'atteinte, de violation de la confidentialité ou d'appropriation illicite de tout brevet, droit d'auteur, marque de commerce ou secret commercial) découlant de ou liée aux présentes Conditions, à la Plateforme et/ou à l'utilisation de la Plateforme, ainsi que toutes les questions d’arbitrabilité. Vous pouvez accéder
              aux Règles des consommateurs à l’adresse {" "}
              <span className="text-blue"> infos@speedfame.net  </span>
              <br />
              <br />
              VOUS NE POUVEZ INTENTER DES RÉCLAMATIONS QU'EN VOTRE CAPACITÉ INDIVIDUELLE ET AU NOM DE VOUS-MÊME, ET NON EN TOUTE CAPACITÉ REPRÉSENTATIVE OU AU NOM DE TOUTE CLASSE OU PRÉTENDUE CLASSE, ET AUCUN ARBITRAGE QUE VOUS COMMENCEZ ICI N'EST JOINT AVEC OU N'INCLUT AUCUNE RÉCLAMATION D'AUTRES PERSONNES.  <br />
              <br /> Chaque partie assumera ses propres frais d’arbitrage. En affichant, en publiant ou en soumettant de quelque manière que ce soit du Contenu Utilisateur sur ou via la Plateforme, vous accordez par les présentes à la Société une licence non exclusive, sous-licenciable, mondiale, entièrement transférable, entièrement payée, irrévocable, perpétuelle, libre de redevance pour utiliser, modifier, interpréter publiquement, afficher publiquement, reproduire, adapter, reformater, publier, diffuser, traduire, créer des œuvres dérivées à partir de, transmettre et distribuer un tel Contenu Utilisateur sur tout support et sur toute plateforme, dans tout média ou technologie, actuel ou développé ultérieurement, sans obligation de paiement à vous ou à un tiers ou la nécessité d'obtenir le consentement d'un tiers. Cette licence comprend le droit d'héberger, d'indexer, de mettre en cache, de distribuer et d'étiqueter tout Contenu Utilisateur, d'éditer et de réviser tout Contenu Utilisateur (sans préavis), ainsi que le droit de sous-licencier le Contenu Utilisateur à des tiers.
              <br /> <br />
              Vous continuez à détenir tous les droits de propriété sur votre Contenu Utilisateur, et vous continuez à avoir le droit d'utiliser votre Contenu Utilisateur de quelque manière que ce soit, sous réserve des présentes Conditions et de la licence décrite ci-dessus. Vous accordez en outre à la Société une licence libre de redevance pour utiliser votre nom d'utilisateur, votre image, votre voix et votre ressemblance pour vous identifier en tant que source de tout Contenu Utilisateur que vous fournissez ; sous réserve, toutefois, que votre capacité à fournir une image, une voix et une ressemblance peut être soumise à des limitations en raison de restrictions d'âge. Vous confirmez que vous êtes propriétaire de tout le contenu soumis, affiché, publié ou publié par vous sur la Plateforme et que vous disposez par ailleurs du droit d'accorder la licence définie ci-dessus, et que l'affichage, la publication ou la publication de tout contenu que vous soumettez, et notre utilisation de ce contenu ne viole pas et ne violera pas les droits à la vie privée, les droits de publicité, les droits d'auteur, les marques de commerce, les brevets, les droits contractuels ou tout autre droit de propriété intellectuelle ou autre droit de toute personne ou entité. Vous acceptez en outre que toutes les informations fournies par vous via cette Plateforme sont vraies et exactes. Tout Contenu Utilisateur sera considéré comme non confidentiel et non propriétaire. Vous ne devez pas publier de Contenu Utilisateur sur ou via la Plateforme ou transmettre à la Société un Contenu Utilisateur que vous considérez comme confidentiel ou exclusif. La Société se réserve le droit de supprimer tout Contenu Utilisateur que vous fournissez, à sa seule discrétion, sans vous en informer.
              <br />
              <br />
              Vous êtes seul responsable de votre conduite et de toutes les données que vous soumettez, publiez, transmettez ou affichez sur ou via la Plateforme. Nous nous réservons le droit, mais nous ne sommes pas obligés, de supprimer, de bloquer et/ou de surveiller, sans préavis, tout utilisateur que nous considérons, pour quelque raison que ce soit, comme violant les présentes Conditions ou étant hors du champ d'application du sujet de la Plateforme. Nous nous réservons en outre le droit, à tout moment et sans préavis, de supprimer ou de désactiver l'accès au contenu ou de refuser d'afficher le contenu à notre discrétion pour quelque raison que ce soit ou sans raison. En plus de tout recours que nous pouvons avoir en droit ou en équité, si nous déterminons, à notre seule discrétion, que vous avez violé ou êtes susceptible de violer les présentes Conditions, nous pouvons prendre toute mesure que nous jugeons nécessaire pour remédier ou prévenir la violation. Dans la mesure permise par la loi, nous n'acceptons aucune responsabilité pour tout contenu soumis par les utilisateurs et publié par nous ou par des tiers autorisés.  <br />
              <br />
              <span className="text-blue font-semibold">3. SERVICE DE DIFFUSION </span>
              <br />
              <br /> CCertaines fonctionnalités de la Plateforme peuvent permettre aux utilisateurs de payer des frais de service ("Frais de service") pour diffuser publiquement leur Contenu Utilisateur via des chaînes, des médias ou des plateformes détenus ou concédés sous licence par nous ("Service de diffusion"). Le Service de diffusion vous permet de choisir une date et une heure de diffusion (dans un bloc de temps prédéterminé), pendant laquelle votre Contenu Utilisateur sera diffusé, s'il a été approuvé et que tous les Frais de service associés ont été payés. L'achat de Services de diffusion ne garantit pas que votre Contenu Utilisateur sera affiché du tout, ou qu'il sera affiché pendant le créneau horaire demandé.
              <br />
              <br /> Si vous achetez un Service de diffusion et téléchargez votre Contenu Utilisateur, nous examinerons alors votre Contenu Utilisateur pour nous assurer qu'il est conforme à la Politique de publication et aux Directives de contenu. Nous vous contacterons soit via la Plateforme, soit via e-mail, avec des mises à jour concernant votre Service de diffusion. Lorsque votre Contenu Utilisateur n'a pas été diffusé pendant votre créneau horaire demandé et que vous avez par ailleurs respecté les présentes Conditions, nous vous créditerons automatiquement d'un montant égal à la valeur des Frais de service payés pour une telle diffusion. Si vous préférez recevoir un remboursement, veuillez contacter infos@speedfame.net. Nous nous réservons le droit de ne pas émettre de remboursements pour les Frais de service liés au Contenu Utilisateur qui enfreint les présentes Conditions. <br />
              <br />
              Une fois que vous avez acheté le Service de diffusion (en payant les Frais de service, en téléchargeant votre Contenu Utilisateur et en choisissant la date et l'heure de diffusion souhaitées), nous vous contacterons pour vous informer : (1) si le Contenu Utilisateur fourni a été approuvé ou rejeté ; (2) quand votre Contenu Utilisateur est prévu d'être diffusé ; (3) avant que votre Contenu Utilisateur ne soit diffusé ; et (4) en cas de problème avec vos Services de diffusion. <br />
              <br />
              <span className="text-blue font-semibold">
                4. FRAIS DE SERVICE ET POLITIQUE DE REMBOURSEMENT{" "}
              </span>
              <br />
              <br /> Certaines fonctionnalités de la Plateforme, telles que le Service de diffusion, sont disponibles à l'achat et vous devrez payer certains frais et coûts ("Frais de service") pour utiliser les fonctionnalités qui sont soumises à des Frais de service ("Fonctionnalités payantes"). Les Frais de service sont déterminés à notre seule discrétion. Les Frais de service peuvent varier et dépendront du type de Fonctionnalités payantes. Les Frais de service seront affichés au moment où vous décidez d'acheter des Fonctionnalités payantes. Vous avez toujours le choix d'accepter ou de refuser les Frais de service. Cependant, si vous refusez de payer les Frais de service, vous ne pourrez pas accéder, visualiser ou utiliser la Fonctionnalité payante à laquelle les Frais de service sont liés. Si vous achetez le Service de diffusion, vous serez présenté avec les Frais de service pour cette Fonctionnalité payante spécifique et si vous l'acceptez, nous placerons une retenue temporaire sur votre mode de paiement qui nous autorisera à facturer votre mode de paiement pour ce montant, une fois le Contenu Utilisateur approuvé. Si votre Contenu Utilisateur est rejeté, vous ne serez facturé d'aucun montant et nous annulerons automatiquement la retenue sur votre mode de paiement. <br />
              <br />
              Tout le Contenu Utilisateur est soumis à la Politique de publication de la section 2 et aux Directives de contenu. Lorsque vous achetez une Fonctionnalité payante telle que le Service de diffusion, vous serez tenu d'accepter les Frais de service qui placeront une retenue sur votre mode de paiement pour le montant des Frais de service. Cependant, soumettre du Contenu Utilisateur et accepter les Frais de service ne garantit pas que votre Contenu Utilisateur sera diffusé publiquement. Nous ne faisons aucune promesse ou garantie que votre Contenu Utilisateur sera diffusé du tout, ou pendant le bloc horaire sélectionné. Nous vous contacterons si votre Contenu Utilisateur a été rejeté ou si le bloc horaire que vous avez sélectionné n'est plus disponible. {" "}
              <br />
              <br />
              Si votre Contenu Utilisateur est rejeté, nous annulerons l'autorisation de retenue et vous ne serez pas facturé de Frais de service liés à ce Contenu Utilisateur. Si votre Contenu Utilisateur est approuvé et programmé pour être diffusé comme demandé ou approuvé par vous, votre mode de paiement sera facturé du montant des Frais de service. Nous n'offrons aucun remboursement pour les Fonctionnalités payantes qui ont déjà été complétées. Par exemple, si vous achetez le Service de diffusion et que votre Contenu Utilisateur a déjà été diffusé, nous ne vous offrirons pas de remboursement des Frais de service payés pour ce service complété. Si vous n'êtes pas satisfait de tout aspect du Service de diffusion que vous avez acheté, veuillez contacter notre équipe de satisfaction client à
              <span className="text-blue"> infos@speedfame.net </span>
              Si nous déterminons, à notre seule discrétion, que vous avez téléchargé du Contenu Utilisateur qui a déjà été rejeté ou qui ne respecte manifestement pas notre Politique de publication et nos Directives communautaires, dans le but de nous ennuyer, de nous harceler ou de nous abuser, ou d'abuser de la Plateforme, nous pouvons choisir de ne pas rembourser les Frais de service associés à une telle transaction et/ou de résilier, bloquer ou suspendre votre accès à la Plateforme ou d'empêcher l'achat de toute Fonctionnalité payante supplémentaire. {" "} <br />
              <br />
              <span className="text-blue font-semibold">
                5. PROCÉDURE POUR LES RÉCLAMATIONS DE VIOLATION DE DROIT 
                D'AUTEUR {" "}
              </span>{" "}
              <br />
              <br />
              Comme la Société demande aux autres de respecter ses droits de propriété intellectuelle, elle respecte les droits de propriété intellectuelle d'autrui et s'attend à ce que les utilisateurs de sa Plateforme fassent de même. La Société, dans des circonstances appropriées, peut supprimer de la Plateforme des informations qui pourraient enfreindre les droits de propriété intellectuelle d’autrui.  <br />
              <br />
              <span className="text-blue font-semibold">6. SITES TIERS </span> <br />
              <br /> CCette Plateforme peut contenir des liens vers des sites non entretenus par ou liés à la Société. Les liens hypertextes sont fournis comme un service aux utilisateurs et ne sont pas parrainés par ou affiliés à cette Plateforme ou à la Société, et la Société ne fait aucune garantie concernant le contenu, l'exhaustivité ou l'exactitude de ces sites tiers. Les informations que vous soumettez sur un site tiers accessible à partir de cette Plateforme sont soumises aux conditions et à la politique de confidentialité de ce site, et la Société n'a aucun contrôle sur la manière dont vos informations sont collectées, utilisées ou autrement traitées. L'inclusion de tout lien vers des sites ou services tiers ne signifie pas nécessairement que la Société approuve ou est affiliée à ce tiers. Vous reconnaissez et acceptez que la Société n'a aucune responsabilité ou obligation pour de tels sites ou services tiers.  <br />
              <br />
              Nous n'excluons ni ne limitons en aucune façon notre responsabilité envers vous lorsque ce serait illégal de le faire. Cela inclut la responsabilité pour le décès ou les blessures corporelles causés par notre négligence ou la négligence de nos employés, agents ou sous-traitants et pour fraude ou fausse déclaration. Veuillez noter que nous ne fournissons cette Plateforme que pour une utilisation domestique et privée. Vous acceptez de ne pas utiliser cette Plateforme à des fins commerciales ou professionnelles, et nous n'avons aucune responsabilité envers vous pour toute perte de profit, perte d'activité, interruption d'activité ou perte d'opportunité commerciale.  <br />
              <br />
              Si un contenu numérique défectueux que nous avons fourni endommage un appareil ou un contenu numérique vous appartenant et que cela est causé par notre manquement à faire preuve d'une compétence et d'une diligence raisonnables, nous réparerons les dommages ou vous verserons une indemnisation. Cependant, nous ne serons pas responsables des dommages que vous auriez pu éviter en suivant nos conseils d'appliquer une mise à jour qui vous a été offerte gratuitement ou pour les dommages causés par votre échec à suivre correctement les instructions d'installation ou à avoir en place les exigences minimales du système conseillées par nous. <br />
              <br />
              Vous promettez que si un Contenu Utilisateur que vous soumettez enfreint ces Conditions, vous serez responsable envers nous et nous indemniserez pour toute violation de cette promesse. Cela signifie que vous serez responsable de toute perte ou dommage que nous subirons à la suite du Contenu Utilisateur que vous téléchargez.  <br />
              <br />
              <span className="text-blue font-semibold">7. RESPONSABILITÉ </span> <br />
              <br />
              Vous reconnaissez que vous ne devez pas vous fier à cette Plateforme. La Société ne donne aucune garantie quant à la quantité de temps que tout Contenu de la Société ou Contenu Utilisateur sera conservé. La Société ne donne aucune garantie que tout matériel, image, application ou fichier obtenu à partir de ou via la Plateforme est exempt de virus informatiques ou d'autres défauts ou erreurs. Il vous incombe de prendre des précautions raisonnables contre les virus informatiques et/ou concernant les inexactitudes, les erreurs typographiques ou autres défauts sur la Plateforme ou les applications ou le matériel disponibles sur ou via la Plateforme.
              <br />
              <br />
              La Société n'approuve, ne vérifie, n'évalue ou ne garantit aucun Contenu Utilisateur ou autre information fournie par les utilisateurs et rien ne doit être considéré comme une approbation, une vérification ou une garantie de tout Contenu Utilisateur, sauf indication expresse contraire. Les points de vue exprimés par d'autres utilisateurs sur la Plateforme et dans le Contenu Utilisateur ne représentent pas nos points de vue ou nos valeurs. Nous ne faisons aucune représentation, garantie ou garantie, qu'elles soient expresses ou implicites, que tout Contenu de la Société (y compris le Contenu Utilisateur) est exact, complet ou à jour. Vous ne devez pas créer ou distribuer des informations, y compris, mais sans s'y limiter, des publicités, des communiqués de presse ou d'autres matériels de marketing, ou inclure des liens vers des sites qui suggèrent une approbation par la Société (y compris son personnel et ses propriétaires) sans l'examen préalable et l'approbation écrite de la Société.
              <br />
              <br />
              Nous pouvons être amenés à transférer les données personnelles de nos utilisateurs à des tiers, sous réserve d'avoir obtenu leur consentement explicite et éclairé. Nous nous engageons à respecter la réglementation en vigueur en matière de protection des données personnelles, et en particulier le Règlement Général sur la Protection des Données (RGPD) français. Nous collectons et traitons les données personnelles de nos utilisateurs de manière licite, loyale et transparente, pour des finalités déterminées, explicites et légitimes, etc.
              <br />
              <br />
              Nous veillons à ce que ces données soient pertinentes, exactes et mises à jour. Nous mettons en œuvre des mesures de sécurité appropriées pour protéger les données personnelles de nos utilisateurs contre tout accès non autorisé, altération, divulgation ou destruction. Nous conservons les données personnelles de nos utilisateurs pendant une durée n'excédant pas celle nécessaire à la finalité pour laquelle elles ont été collectées, et nous les supprimons ou les anonymisons à l'issue de cette durée. Nos utilisateurs disposent de droits sur leurs données personnelles, qu'ils peuvent exercer en nous contactant à l'adresse suivante :
              <span className="text-blue">infos@speedfame.net </span>
              Nous nous engageons à répondre à leurs demandes dans les meilleurs délais et à leur fournir toutes les informations nécessaires à l'exercice de leurs droits.
            </p>
          </div>
        </div>
      </section>
      <div className="fixed bottom-0 w-full">
        <Footer />
      </div>
    </>
  );
};

export default Terms;
