import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <App />
    {/* <StripeProvider apiKey="pk_test_12345"> */}
    {/* </StripeProvider> */}
  </> 

  // </React.StrictMode>
);