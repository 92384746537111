import React from "react";

// Components
import Layout from "../../components/layout/Layout";
import ContactForm from "./components/contactform";

const ContactUs = () => {
  return (
    <Layout>
      <ContactForm />
    </Layout>
  );
};

export default ContactUs;
