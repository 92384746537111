import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
// Components
import Layout from "../../../../src/components/layout/Layout";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import { Button } from "../../../../src/components/ui/button";
import toast from "react-hot-toast";
import interceptor from "../../../hooks/intercepter";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/ui/Loader";

const stripePromise = loadStripe(
  "pk_live_51P7egGGfpUbrWYdPqk6WxSMjvPArJT1PAY27QC7cxoYaejx5QjpKHMNHNnhYXNmUrLxhv8bolvt9pPMNzPVhvZ7000TZt9ptgj"
);

const AdvertiserAddContent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const [packages, setPackages] = useState();
  const [duration, setDuration] = useState();
  const pathSegments = window.location.pathname.split("/");
  const userId = pathSegments[3];
  const navigate = useNavigate();
  const broadcasterId = pathSegments[4];
  const [id, setId] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [terms, setTerms] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(false);
  const [clientIntent, setClientIntent] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [advertiserId, setAdvertiserId] = useState();
  const [contentId, setContentId] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    package: "",
    phone: "",
  });
  const handleNextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      console.log("Validate & Pay");
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }

  };

  const dots = (step) => {
    setCurrentStep(step);
  };

  const selectedPackage = (value) => {
    setErrorMessage('')
    setPackages(value)
  }

  const selectedTerms = (value) => {
    setErrorMessage('')
    setTerms(value.terms)
    setEmail(value.email)
    setFormData({ ...formData, email: value.email, package: packages, phone: value.phoneNumber });
    setPhoneNumber(value.phoneNumber)
    if (value.phoneNumber && (value.phoneNumber.length == 9)) {
    }
    else {
      setErrorMessage('Phone Number length should be 9')
    }

  }
  const options = {
    clientSecret: clientSecret,
  };

  const selectedFile = (value) => {
    console.log(value)
    setFile(value)
    if (value.duration > duration) {
      setErrorMessage(`Your video duration is more than ${duration}s . Please select another video or reduce the video duration.`)
      setError(true)
    }
    else {
      setErrorMessage('')
      setError(false)
    }
  }

  const getFileDuration = (value) => {
    try {
      interceptor.axiosGet("get", `packages/content-duration`).then((res) => {
        if (res?.status === 200) {
          console.log(res?.data?.data?.content_duration)
          setDuration(res?.data?.data?.content_duration);
        }

      });

    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  }



  useEffect(() => {
    if (id) setError(false);
    if (file) setError(false);
    if (formData.email) setError(false);
    if (terms) setError(false);
    if (!duration) {
      getFileDuration()
    }

  }, [id, file, formData.email, terms]);

  const handleSubmit = async () => {
    if (currentStep < totalSteps) {
      console.log(errorMessage)
      if (!errorMessage) {
        if (currentStep === 1 && !file) {
          setError(true);
          setErrorMessage("Content is required*");
          return;
        }
        else if (currentStep === 2 && !packages) {
          setError(true);
          setErrorMessage("Package is required*");
          return;
        }
        else if (currentStep === 3 && (!terms)) {
          setError(true);
          setErrorMessage("Please accept terms of use*");
          return;
        }
        else if (currentStep === 3 && formData.email === '') {
          setError(true);
          setErrorMessage("Email is required*");
          return;
        }
        else if (currentStep === 3 && formData.phone === '') {
          setError(true);
          setErrorMessage("PhoneNumber is required*");
          return;
        }
        else {
          setError(false);
          setErrorMessage('');
          setCurrentStep((prevStep) => prevStep + 1);
        }
      }
    } else {
      console.log("Validate & Pay");

      if (!packages) {
        setError(true);
        setErrorMessage("Package is required*");
        return;
      } else if (!file.file) {
        setError(true);
        setErrorMessage("Content is required*");
        return;
      } else if (!formData.email) {
        setError(true);
        setErrorMessage("Email is required*");
        return;
      }
      else if (!formData.phone) {
        setError(true);
        setErrorMessage("PhoneNumber is required*");
        return;
      } else if (terms === false) {
        setError(true);
        setErrorMessage("Accept Terms of Use*");
        return;
      } else {
        setError(false);
      }
      setLoading(true);
      try {
        // First call to advertiser/payment
        const paymentResponse = await interceptor.axiosPostWithoutToken(
          "advertiser/payment",
          { email: formData.email, package_id: packages, phone: '+33' + formData.phone }
        );
        const { advertiser_id, payment_intent_id, client_secret } =
          paymentResponse.data;

        if (paymentResponse.status === false) {
          setLoading(false);
          toast.error(paymentResponse.message);
        }
        const formData1 = new FormData();
        formData1.append("file", file.file);
        formData1.append("screen_id", userId);
        formData1.append("user_id", broadcasterId);
        formData1.append("package_id", packages);
        formData1.append("email", email);
        formData1.append("phoneNumber", phoneNumber);
        formData1.append("advertiser_id", advertiser_id);
        formData1.append("title", title);

        // Second call to advertiser/content
        const contentResponse = await interceptor.axiosPostFormData(
          "advertiser/content",
          formData1,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (contentResponse?.status === false) {
          toast.error(contentResponse?.message);
          setLoading(false);
        } else {
          setClientSecret(client_secret);
          setClientIntent(payment_intent_id);
          setAdvertiserId(advertiser_id);
          console.log(contentResponse?.data?.data.content_id);
          setContentId(contentResponse?.data?.data?.content_id);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Upload failed:", error);
        setError(true);
        setErrorMessage("Upload failed. Please try again.");
      }
    }

  };

  const handleCancelPayment = async () => {
    try {
      await interceptor.axiosPost(
        "advertiser/update/payment",
        {
          payment_intent_id: clientIntent,
          user_id: advertiserId,
          package_id: id,
          payment_status: "cancel",
          content_id: contentId,
        },
        null
      );
      toast.success("Payment cancelled successfully!");
      setClientSecret(null);
    } catch (error) {
      toast.error("Failed to cancel payment.");
      console.error("Error cancelling payment:", error);
    }
  };

  const handlePaymentSuccess = async () => {
    
    await interceptor.axiosPostWithoutToken("advertiser/update/payment", {
      payment_intent_id: clientIntent,
      payment_status: "confirm",
      user_id: advertiserId,
      package_id: packages,
      content_id: contentId,
    });
    setFile(null);
    setFileType("");
    setFileName("");
    setVideoThumbnail(null);
    // setImagePreview(null);
    setUploadProgress(0);
    setFormData({
      email: "",
      package: "",
      phone: "",
    });
    setId("");
    setLoading(false);

    toast.success("Content uploaded successfully!");
    navigate(`/advertiser/thankyou/${userId}/${broadcasterId}`);
  };


  return (
    <Layout>

      {
        loading && (
          <Loader />
        )
      }
      <>
        <div className="md:pt-20 pt-10 flex justify-center mb-10">
          {Array.from({ length: totalSteps }).map((_, index) => (
            <div
              key={index}
              onClick={() => dots(index + 1)}
              className={`dot w-3.5 h-3.5 rounded-full mx-2 cursor-pointer ${currentStep === index + 1 ? "bg-blue w-7" : "bg-light-gray"
                }`}
            />
          ))}
        </div>
        {currentStep == 1 && <div><StepOne onSelectedFile={selectedFile} maxDuration={duration} item={file} /></div>}
        {currentStep == 2 && <div><StepTwo onSelectedPackage={selectedPackage} duration={file?.duration} seleted={packages} /></div>}
        {currentStep == 3 && <div><StepThree onSelectedTems={selectedTerms} packageId={packages} selectEmail={email} selectTerms={terms}
          videoThumbnail={file?.videoThumbnail} name={file?.name} image={file?.imagePreview}
        /></div>}
        <div className="md:container mx-auto px-5 mt-2">
          <div className="lg:w-6/12 w-full mx-auto text-sm" style={{ color: 'red ' }}>
            {errorMessage}
          </div>
        </div>


        <div className="md:container mx-auto px-5">
          <div className="text-center mt-7 md:mb-20 mb-10">
            <Button
              type="button"
              onClick={handleSubmit}
              // disabled={currentStep === totalSteps}
              className="px-8 py-3.5 h-auto"
            >
              {currentStep === totalSteps ? "Validate & Pay" : "Étape suivante >"}
            </Button>
          </div>

          {currentStep > 1 && (
            <div className="mb-10">
              <Button
                type="button"
                onClick={handlePreviousStep}
                className="bg-dark px-8 py-3.5 h-auto"
              >
                {"<"} Étape Précédente
              </Button>
            </div>
          )}
        </div>
      </>
      {clientSecret && (
        <section className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative bg-white content-modal p-4 pe-2 rounded-lg shadow-lg">
            <div
              className="rounded-lg p-4 pe-6"
              style={{ width: 400, height: 500, overflowY: "scroll" }}
            >
              <Elements stripe={stripePromise} options={options}>
                <PaymentForm
                  clientSecret={clientSecret}
                  onPaymentSuccess={handlePaymentSuccess}
                />
              </Elements>
              <button
                className="absolute top-4 right-4 flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full shadow-md text-gray-600 hover:bg-gray-200"
                onClick={handleCancelPayment}
                aria-label="Close"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

        </section>
      )}

    </Layout>


  );
};
export default AdvertiserAddContent;
