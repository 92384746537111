import React, { useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button } from "../../../components/ui/button"; // adjust path as needed

const PaymentForm = ({ clientSecret, setLoading, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/thank-you/id`, // Specify where you want Stripe to redirect the user after payment
      },
      redirect: "if_required",
    });

    if (error) {
      console.log(error);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      onPaymentSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex justify-center">
        <Button type="submit" className="mt-5" disabled={!stripe}>
          Pay
        </Button>
      </div>
    </form>
  );
};

export default PaymentForm;
