import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod"; // Import Zod
import { useForm } from "react-hook-form"; // Import React Hook Form
import AppLayout from "../../../components/applayout/AppLayout";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../hooks/intercepter";
import toast from "react-hot-toast";
import Loader from "../../../components/ui/Loader";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormField } from "../../../components/ui/form";
import { PhoneNumberInput } from "../../../components/ui/phoneInput";

// Define validation schema using Zod
const schema = z.object({
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().min(1, "Email is required").email("Invalid email"),
  phone: z
    .string()
    .min(1, "Phone is required!")
    .regex(/^\+33\d{9}$|^\+[1-9]\d{10,12}$/, "Invalid phone number"),
  location_name: z.string().min(1, { message: "Location name is required" }),
  location_address: z
    .string()
    .min(1, { message: "Location address is required" }),
  user_type: z.number(),
});

const AddUser = () => {
  // const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState();
  const { token } = useToken();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      location_name: "",
      location_address: "",
      user_type: 1,
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);

    try {
      // Validate using Zod
      schema.parse(data);

      const response = await interceptor.axiosPost("users", data, token);
      setError(response.message);
      if (response.status === 200) {
        // Reset form after successful submission
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          location_name: "",
          location_address: "",
          user_type: 1,
          is_active: true,
        });
        handleChangeStatus(response.data.data.id);
        setLoading(false);
        navigate("/admin/users");
        toast.success("User Added Successfully");
      } else {
        console.log(response.message);
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const firstError = error.errors[0].message;
        setError(firstError);
      } else {
        setError("Failed to add user");
      }
      setLoading(false);
      toast.error("Failed to add user");
      console.error("Upload failed:", error);
    }
  };

  const handleChangeStatus = async (id) => {
    try {
      const response = await interceptor.axiosPut(
        "PUT",
        `users/status`,
        {
          id: id,
          is_active: true,
        },
        token
      );

      if (response.status === 200) {
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <section className="mb-20 sm:mb-24">
          <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
          Ajouter un utilisateur
          </h5>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col my-6 gap-7 sm:my-8"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-7">
              <div>
                <FormField
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <Fragment>
                      <Input
                        type="text"
                        name="first_name"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="First Name"
                      />
                      {errors.first_name && (
                        <p className="text-sm text-red">
                          {errors.first_name.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <Fragment>
                      <Input
                        type="text"
                        name="last_name"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Last Name"
                      />
                      {errors.last_name && (
                        <p className="text-sm text-red">
                          {errors.last_name.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Fragment>
                      <Input
                        type="text"
                        name="email"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Email"
                      />
                      {errors.email && (
                        <p className="text-sm text-red">
                          {errors.email.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <Fragment>
                      <PhoneNumberInput
                        type="text"
                        name="phone"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Phone"
                      />
                      {errors.phone && (
                        <p className="text-sm text-red">
                          {errors.phone.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={control}
                  name="location_name"
                  render={({ field }) => (
                    <Fragment>
                      <Input
                        type="text"
                        name="location_name"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Location Name"
                      />
                      {errors.location_name && (
                        <p className="text-sm text-red">
                          {errors.location_name.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
              <div>
                {" "}
                <FormField
                  control={control}
                  name="location_address"
                  render={({ field }) => (
                    <Fragment>
                      <Input
                        type="text"
                        name="location_address"
                        {...field}
                        className="text-sm font-medium text-dark bg-background w-full h-[3.063rem] px-5 rounded-xl border-none"
                        placeholder="Location Address"
                      />
                      {errors.location_address && (
                        <p className="text-sm text-red">
                          {errors.location_address.message}
                        </p>
                      )}
                    </Fragment>
                  )}
                />
              </div>
            </div>
            <div>
              <Button size="sm" className="px-12 w-fit" type="submit">
              Ajouter un utilisateur
              </Button>
            </div>
          </form>
        </section>
      )}
    </AppLayout>
  );
};

export default AddUser;
