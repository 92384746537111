import React, { Fragment } from "react";

// Components
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import AuthTabs from "../authtabs";
import Otp from "../../Otp";

const AuthDialog = () => {
  return (
    <Fragment>
      {/* <Otp /> */}
      <AuthTabs />

    </Fragment>
  );
};

export default AuthDialog;
