import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const BroadCasterRoutes = () => {
    const { token,isAdmin, isBroadCaster } = useToken();
    return  (token && !isAdmin && isBroadCaster) ? <Outlet /> : <Navigate to="/broadcaster/dashboard" replace />
}

export default BroadCasterRoutes;