import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Components
import { Input } from "../../../components/ui/input";
import {
  DeleteIcon,
  GalleryIcon,
  ViewIcon,
  VideoIcon,
  CloseIcon,
} from "../../../assets/icons/Icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import AppLayout from "../../../components/applayout/AppLayout";
import Loader from "../../../components/ui/Loader";
import useToken from "../../../hooks/useToken";

// Assets
import tableImg from "../../../assets/images/hero-img.png";

// Interceptor
import interceptor from "../../../hooks/intercepter.js";
import ReactPaginate from "react-paginate";
import { ImageIcon, PlayIcon, SidebarCloseIcon, Video } from "lucide-react";
import { baseUrl } from "../../../hooks/intercepter.js";
import toast from "react-hot-toast";

const AddContent = () => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [title, setTitle] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contentList, setContentList] = useState([]);
  const [contentListByAdvertiser, setContentListByAdvertiser] = useState([]);
  const { token, removeToken, user } = useToken();

  const [itemOffset, setItemOffset] = useState(0);
  const [itemOffset1, setItemOffset1] = useState(0);
  const itemsPerPage = 6;
  const endOffset = itemOffset + itemsPerPage;
  const endOffset1 = itemOffset1 + itemsPerPage;
  const currentItems = contentList.slice(itemOffset, endOffset);
  const ItemsByAdvertiser = contentListByAdvertiser.slice(
    itemOffset1,
    endOffset1
  );
  const pageCount = Math.ceil(contentList.length / itemsPerPage);
  const pageCountByAdvertiser = Math.ceil(
    contentListByAdvertiser.length / itemsPerPage
  );

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  const handlePageClickByAdvertiser = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset1(newOffset);
  };

  console.log("currentItems", currentItems);

  useEffect(() => {
    fetchContent();
    // fetchContentByAdvertiser();
  }, []);

  const fetchContent = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/combined-content/${user.id}`,
        token
      );
      console.log(response?.data?.data?.result);
      if (response.status) {
        setContentList(response?.data?.data?.result);
        // toast.success("Content fetched successfully");
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Failed to fetch content");
    }
  };

  const fetchContentByAdvertiser = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        "advertiser/content",
        null
      );
      console.log(response.data.data.result);
      if (response.status) {
        setContentListByAdvertiser(response.data.data.result);
        // toast.success("Content fetched successfully");
      }
    } catch (error) {
      console.error("Failed to fetch contentsdfsdf:", error);
      toast.error("Failed to fetch contentasfdsfsdf");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await interceptor.axiosDelete(
        "DELETE",
        `broadcaster/content/${id}`,
        null,
        token
      );
      console.log("Delete successful:", response.data);
      fetchContent();
      toast.success("Content deleted successfully");
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Failed to delete content");
      if (error.status === 401) {
        removeToken();
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile);

    if (selectedFile.type.startsWith("video/")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = () => {
        if (video.duration > 11) {
          alert("Please select a video that is no longer than 10 seconds.");
          setFile(null);
          setFileType("");
          setFileName("");
          setTitle("");
          setVideoThumbnail(null);
          setImagePreview(null);
          e.target.value = null;
        } else {
          setFile(selectedFile);
          setFileType(selectedFile.type);
          setFileName(selectedFile.name);
          setTitle(selectedFile.name);
          generateVideoThumbnail(selectedFile);
        }
      };
    } else if (selectedFile.type.startsWith("image/")) {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileName(selectedFile.name);
      setTitle(selectedFile.name);
      setImagePreview(URL.createObjectURL(selectedFile));
      setVideoThumbnail(null);
    }
  };

  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = 1;

    video.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setVideoThumbnail(thumbnailUrl);
      setImagePreview(null);
    };
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);

    try {
      const response = await interceptor.axiosPostFormData(
        "broadcaster/content",
        formData,
        token,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      if (response?.data?.status === true) {
        setLoading(false);
        toast.success("Upload successful");
      } else {
        setLoading(false);
        toast.error(response?.message);
      }
      fetchContent();
      setFile(null);
      setFileType("");
      setFileName("");
      setTitle("");
      setVideoThumbnail(null);
      setImagePreview(null);
      setUploadProgress(0);
      setShowModal(false);
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error?.message);
    }
  };

  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setShowModalDelete(true);
  };

  const handleModalClose = () => {
    setShowModalDelete(false);
    setDeleteId(null);
  };

  const handleModalConfirm = () => {
    handleDelete(deleteId);
    handleModalClose();
  };

  const handleAddContentClick = () => {
    setFile(null);
    setFileType("");
    setFileName("");
    setTitle("");
    setVideoThumbnail(null);
    setImagePreview(null);
    setUploadProgress(0);
    setShowModal(true);
  };

  const handleViewContent = (content) => {
    setSelectedContent(content);
    setShowContentModal(true);
  };

  const handleCloseContentModal = () => {
    setShowContentModal(false);
    setSelectedContent(null);
  };

  return (
    <AppLayout>
      {loading ? (
        <Loader content="Uploading" />
      ) : (
        <>
          {/* <ToastContainer /> */}
          <section className="mb-20 sm:mb-24">
            <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
              Ajouter un contenu
            </h5>
            <button
              onClick={handleAddContentClick}
              className="bg-blue rounded-xl text-white text-sm font-medium px-8 py-3.5 mt-6"
            >
              Ajouter un contenu
            </button>
            <div className="mt-6 overflow-x-auto sm:mt-8 whitespace-nowrap">
              <div className="w-full overflow-auto">
                <Table className="w-full overflow-hidden border border-light-gray rounded-xl">
                  <TableHeader className="font-semibold">
                    <TableRow>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-semibold">Numéro</h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-semibold">Contenu</h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">
                          Titre
                        </h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">
                          Source
                        </h6>
                      </TableHead>
                      <TableHead className="p-3 text-left">
                        <h6 className="text-sm font-normal">Actions</h6>
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentItems.map((currentContent, index) =>
                      currentContent.content_type === "qr_code" ? null : (
                        <TableRow
                          key={currentContent.id}
                          className="align-middle"
                        >
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {index + 1}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent?.content_type === "mov" ||
                              currentContent?.content_type === "mp4" ||
                              currentContent?.content_type === "gif" ? (
                              <VideoIcon
                                width={60}
                                height={60}
                                className="block object-cover rounded-xl w-[3.75rem] h-[3.75rem] flex-shrink-0 flex-grow-0"
                                stroke="#242331"
                              />
                            ) : (
                              <img
                                className="w-16 h-16 rounded-md"
                                src={
                                  baseUrl +
                                  "media/" +
                                  currentContent.content_path
                                }
                                alt={currentContent.title}
                              />
                            )}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent.title}
                          </TableCell>
                          <TableCell className="p-3 text-sm font-normal text-left align-middle whitespace-nowrap">
                            {currentContent.source === "advertiser"
                              ? "Annonceur"
                              : "Vous"}
                          </TableCell>
                          <TableCell className="text-right py-2.5 flex items-center align-middle gap-2.5 h-14 pr-5">
                            <button
                              onClick={() => handleViewContent(currentContent)}
                              className="flex items-center justify-center w-6 h-6 rounded-md text-white bg-blue"
                            >
                              <ViewIcon className="w-5 h-5" />
                            </button>

                            {currentContent.source !== "advertiser" && (
                              <Link
                                to="#"
                                className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                              >
                                <button
                                  onClick={() =>
                                    handleConfirmDelete(currentContent.id)
                                  }
                                  className="flex items-center justify-center w-6 h-6 rounded-md bg-red"
                                >
                                  <DeleteIcon
                                    stroke="white"
                                    width={14}
                                    height={14}
                                  />
                                </button>
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div className="mt-6 sm:mt-8">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Suivant >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={6}
                pageCount={pageCount}
                previousLabel="< Précédent"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </section>
          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showModal ? "" : "hidden"
              }`}
          >
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">Ajouter un contenu</h3>
              <div className="mt-4">
                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
              </div>
              {file && (
                <div className="mt-4">
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title"
                  />
                </div>
              )}
              {file && (
                <div className="mt-4">
                  {fileType.startsWith("video/") ? (
                    <video
                      className="w-full h-64"
                      controls
                      src={URL.createObjectURL(file)}
                    />
                  ) : (
                    <img
                      className="w-full h-64"
                      src={URL.createObjectURL(file)}
                      alt={fileName}
                    />
                  )}
                </div>
              )}
              {file && uploadProgress > 0 && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  />
                </div>
              )}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowModal(false)}
                  className="p-2 text-white rounded bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpload}
                  className="p-2 ml-2 text-white rounded bg-blue"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>

          <div
            className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showModalDelete ? "" : "hidden"
              }`}
          >
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">Confirm Delete</h3>
              <p className="mt-4">
                Are you sure you want to delete this content?
              </p>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={handleModalClose}
                  className="p-2 text-white rounded bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleModalConfirm}
                  className="p-2 ml-2 text-white rounded bg-red"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

          {showContentModal && selectedContent && (
            <div className="fixed inset-0 content-modal z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-between items-start">
                  <h3 className="text-xl font-semibold mb-4">
                    {selectedContent.title}
                  </h3>
                  {/* <p>dfghj</p> */}
                  <div className="h-3">
                    <button
                      onClick={handleCloseContentModal}
                      className=" text-4xl text-gray-500 hover:text-gray-800 p-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-square-x"
                      >
                        <rect
                          width="18"
                          height="18"
                          x="3"
                          y="3"
                          rx="2"
                          ry="2"
                        />
                        <path d="m15 9-6 6" />
                        <path d="m9 9 6 6" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="h-[400px] overflow-scroll">
                  {selectedContent?.content_type === "mov" ||
                    selectedContent?.content_type === "mp4" ||
                    selectedContent?.content_type === "gif" ? (
                    <video
                      className="w-full h-full"
                      controls
                      src={baseUrl + "media/" + selectedContent.content_path}
                    />
                  ) : (
                    <img
                      className="w-full h-auto"
                      src={baseUrl + "media/" + selectedContent.content_path}
                      alt={selectedContent.title}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </AppLayout>
  );
};

export default AddContent;
