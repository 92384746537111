import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const PrivateRoutes = () => {

    const { token } = useToken();
    return (token ? <Outlet />  : <Navigate to="/" replace />)
}

export default PrivateRoutes;